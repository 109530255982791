import { CommitData } from "@/store/modules/review";

export function getCommitTime(c: CommitData): number | undefined {
  const date = c.commit.pushedDate || c.commit.committedDate;
  if (!date) {
    return undefined;
  }

  return new Date(date).getTime();
}
