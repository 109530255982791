





















































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { httpsCallable } from "firebase/functions";

import SplitButton from "@/components/elements/SplitButton.vue";
import SimpleLabeledSelect from "@/components/elements/SimpleLabeledSelect.vue";
import SettingsInstallationItem from "@/components/elements/SettingsInstallationItem.vue";

import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";
import InboxModule from "../../store/modules/inbox";

import { getApiUrl } from "../..//plugins/api";
import { functions } from "../../plugins/firebase";
import { config } from "../../plugins/config";
import { TraceMethod } from "../../plugins/perf";
import { LocalSettings } from "../../plugins/localSettings";

import { Github } from "../../../../shared/github";
import { Latch } from "../../../../shared/asyncUtils";
import {
  BillingInfo,
  DiffMode,
  DiffServer,
  SortOrder,
  ThemeName,
  UserInvite
} from "../../../../shared/types";
import { canShadowAsAdmin } from "../../../../shared/typeUtils";
import { FLAGS, getBooleanFlag } from "@/plugins/flags";

@Component({
  components: {
    SplitButton,
    SimpleLabeledSelect,
    SettingsInstallationItem
  }
})
export default class Settings extends Vue {
  private authModule = getModule(AuthModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);
  private inboxModule = getModule(InboxModule, this.$store);

  public needsAccess = false;
  public settingsLoaded = false;
  public usageLoaded = false;
  public billingResult: string | null = null;

  public emailToInvite = "";
  public userToShadow = "";

  public diffMode: DiffMode = LocalSettings.getOrDefault(
    LocalSettings.KEY_DIFF_MODE,
    "split"
  );

  public sortOrder: string = LocalSettings.getOrDefault(
    LocalSettings.KEY_SORT_ORDER,
    "diff-desc"
  );

  public diffServer: DiffServer = LocalSettings.getOrDefault(
    LocalSettings.KEY_DIFF_SERVER,
    "github"
  );

  public theme: ThemeName = LocalSettings.getOrDefault(
    LocalSettings.KEY_THEME,
    "dark"
  );

  @TraceMethod("Settings#mounted")
  public async mounted() {
    this.uiModule.beginLoading();
    this.needsAccess = this.$route.query["needsAccess"] === "true";
    this.billingResult = this.$route.query["billingResult"] as string | null;

    if (this.billingResult === "success") {
      this.uiModule.addMessage({
        text:
          "Thank you for subscribing! Your acccount will be updated shortly.",
        type: "alert"
      });
    }

    if (this.billingResult === "cancel") {
      this.uiModule.addMessage({
        text: "There was an error processing your subscription.",
        type: "error"
      });
    }

    const github: Github = new Github(
      AuthModule.getDelegate(this.authModule),
      config.github
    );

    const dbUserPromise = this.authModule.loadDatabaseUser();
    const userReposPromise = this.authModule.loadUserRepos(true);
    const installationsPromise = this.inboxModule.loadInstallations({ github });
    const invitesPromise = this.inboxModule.loadInvites({
      uid: this.authModule.assertUser.uid
    });

    const latch = new Latch([
      dbUserPromise,
      userReposPromise,
      installationsPromise,
      invitesPromise
    ]);

    try {
      await latch.wait();
    } catch (e) {
      const errors = (e as any).errors || [];
      console.warn("Error loading settings", errors);
    }

    await this.inboxModule.loadReposForInstallations();
    this.settingsLoaded = true;

    await this.inboxModule.loadUsageForInstallations();
    this.usageLoaded = true;

    this.uiModule.endLoading();
  }

  public async refreshUserRepos() {
    this.uiModule.beginLoading();
    const fn = httpsCallable(functions(), "api/refreshUserRepos");
    try {
      await fn();
      await this.authModule.loadUserRepos(true);
    } catch (e) {
      console.error("refreshUserRepos", e);
    }
    this.uiModule.endLoading();
  }

  public enableDiffServer() {
    return getBooleanFlag(FLAGS.ENABLE_DIFF_SERVER);
  }

  public saveDiffMode(val: DiffMode) {
    this.diffMode = val;
    LocalSettings.set(LocalSettings.KEY_DIFF_MODE, this.diffMode);
  }

  public saveSortOrder(val: SortOrder) {
    this.sortOrder = val;
    LocalSettings.set(LocalSettings.KEY_SORT_ORDER, this.sortOrder);
  }

  public saveDiffServer(val: DiffServer) {
    this.diffServer = val;
    LocalSettings.set(LocalSettings.KEY_DIFF_SERVER, this.diffServer);
  }

  public saveTheme(val: ThemeName) {
    this.theme = val;
    LocalSettings.set(LocalSettings.KEY_THEME, this.theme);

    // Need to reload the page after a theme change
    window.location.reload();
  }

  public async sendInvite() {
    if (!this.emailToInvite) {
      return;
    }

    const invite: UserInvite = {
      type: "user",
      email: this.emailToInvite,
      invited_by: this.authModule.assertUser.uid
    };

    this.uiModule.beginLoading();
    await this.inboxModule.addInvite({ invite });

    this.emailToInvite = "";
    this.uiModule.endLoading();
  }

  public async shadowUser() {
    if (!this.userToShadow) {
      return;
    }

    const fn = httpsCallable(functions(), "__admin/shadow");
    const res = await fn({
      login: this.userToShadow
    });

    const url = (res.data as any).url;
    if (url) {
      window.location.replace(url);
    }
  }

  public async deleteInvite(email: string) {
    this.uiModule.beginLoading();
    await this.inboxModule.deleteInvite({
      email,
      uid: this.authModule.assertUser.uid
    });
    this.uiModule.endLoading();
  }

  public formatPrice(priceInCents: number) {
    return `$${(priceInCents / 100).toFixed(2)}`;
  }

  public billingLinkUrl(opts: { org: string; info: BillingInfo }) {
    if (opts.info.freeTrial) {
      return getApiUrl(`/internal/orgs/${opts.org}/checkout-session`);
    }

    return getApiUrl(`/internal/orgs/${opts.org}/billing-portal`);
  }

  get loaded() {
    return !!this.authModule.user && this.settingsLoaded;
  }

  get title() {
    return "Settings";
  }

  get installations() {
    return this.inboxModule.installations;
  }

  get invites() {
    return this.inboxModule.invites;
  }

  get userRepoNames() {
    return this.authModule.userRepoNames;
  }

  get newInstallationUrl() {
    return config.github.app_url + "/installations/new";
  }

  get usage() {
    return Object.entries(this.inboxModule.billing)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(e => ({
        org: e[0],
        info: e[1]
      }));
  }

  get canShadowAsAdmin() {
    const databaseUser = this.authModule.databaseUser;
    if (!databaseUser) {
      return false;
    }
    return canShadowAsAdmin(databaseUser);
  }
}
