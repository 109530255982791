/**
 * DO NOT CHANGE THIS FUNCTION. Hash codes may have been stored
 * in places where they are expected to be stable.
 *
 * Also ... don't use this for anything serious.
 *
 * See:
 * - https://stackoverflow.com/a/8831937
 * - https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
 */
export function hashCode(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
}
