// Replacement for AggregateError
export class MultipleError extends Error {
  constructor(public errors: Error[], message?: string) {
    super(message);
  }
}

export class Latch {
  private total: number = 0;
  private completed: number = 0;
  private done: Promise<void>;
  private res!: () => void;
  private rej!: (e: Error) => void;

  private resultsMap: Map<Promise<any>, any> = new Map();
  private errorsMap: Map<Promise<any>, any> = new Map();

  constructor(promises: Promise<unknown>[] = []) {
    this.done = new Promise((res, rej) => {
      this.res = res;
      this.rej = rej;
    });

    for (const p of promises) {
      this.incrementPromise(p);
    }
  }

  increment() {
    this.total += 1;
  }

  decrement(i: number = 1) {
    this.completed += i;
    if (this.completed >= this.total) {
      if (this.getErrors().length === 0) {
        this.res();
      } else {
        this.rej(new MultipleError(this.getErrors()));
      }
    }
  }

  incrementPromise(p: Promise<unknown>) {
    this.increment();
    p.then((r) => {
      this.resultsMap.set(p, r);
      return r;
    })
      .catch((e) => {
        this.errorsMap.set(p, e);
      })
      .finally(() => {
        this.decrement();
      });
  }

  getResult<T>(p: Promise<T>): T | undefined {
    if (this.resultsMap.has(p)) {
      return this.resultsMap.get(p) as T;
    }
    return undefined;
  }

  getError(p: Promise<any>): any | undefined {
    if (this.errorsMap.has(p)) {
      return this.errorsMap.get(p);
    }
    return undefined;
  }

  getErrors() {
    return Array.from(this.errorsMap.values());
  }

  async wait(): Promise<Latch> {
    await this.done;
    return this;
  }
}
