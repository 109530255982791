


























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ToggleSwitch from "@/components/elements/ToggleSwitch.vue";

import { InstallationData } from "../../../../shared/github";

import InboxModule from "../../store/modules/inbox";

@Component({
  components: {
    ToggleSwitch
  }
})
export default class SettingsInstallationItem extends Vue {
  @Prop() public i!: InstallationData;

  public repos: Array<{
    repo: InstallationData["repositories"][number];
    auto_review: boolean;
    default_resolve_threads: boolean;
  }> = [];

  public expanded = false;

  private inboxModule = getModule(InboxModule, this.$store);

  async mounted() {
    this.repos = this.i.repositories.map(repo => ({
      repo,
      auto_review: this.inboxModule.repoAutoReviewEnabled(
        this.i.account.login,
        repo.name
      ),
      default_resolve_threads: this.inboxModule.repoDefaultResolveThreads(
        this.i.account.login,
        repo.name
      )
    }));
  }

  get orgAutoReview() {
    return this.inboxModule.orgAutoReviewEnabled(this.i.account.login);
  }

  public onOrgToggleAutoReview(val: boolean) {
    this.inboxModule.toggleAutoReview({ org: this.i.account.login, val });
  }

  public onRepoToggleAutoReview(repo: string, val: boolean) {
    this.inboxModule.toggleAutoReview({ org: this.i.account.login, repo, val });
  }

  public onRepoToggleResolveThreadsByDefault(repo: string, val: boolean) {
    this.inboxModule.toggleResolveThreadsByDefault({
      org: this.i.account.login,
      repo,
      val
    });
  }
}
