import { auth } from "./firebase";

export function getApiUrl(path: string) {
  const host =
    process.env.NODE_ENV !== "production"
      ? "http://localhost:5000"
      : "https://codeapprove.com";

  return `${host}${path}`;
}

export async function get(
  path: string,
  options?: {
    headers?: {
      "x-codeapprove-github-token"?: string;
    };
  }
) {
  const url = getApiUrl(path);
  const token = await auth().currentUser?.getIdToken();
  const authHeader = token ? `Bearer ${token}` : "";

  const res = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: new Headers({
      Authorization: authHeader,
      ...(options?.headers || {})
    })
  });

  return await res.json();
}
