import { User as FirebaseUser } from "firebase/auth";

/**
 * TODO(polish): This type name overlaps with the database user type
 * which is annoying!
 */
export interface User {
  uid: string;
  email: string;
  username: string;
  photoURL: string;

  githubToken: string;
  githubExpiry: number;
}

export function createUser(
  u: FirebaseUser,
  githubToken: string,
  githubExpiry: number
): User {
  return {
    uid: u.uid,
    username: u.displayName!,
    email: u.email!,
    photoURL: u.photoURL!,
    githubToken,
    githubExpiry
  };
}
