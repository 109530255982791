// Register the router hooks for components (the docs say to do this first)
import { Component } from "vue-property-decorator";
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave"]);

// Vue!
import Vue from "vue";

// Base styles
import "./assets/styles/index.css";

// Store
import store from "@/store";

// Cookies
// https://github.com/cmp-cc/vue-cookie
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Router
import router from "@/plugins/router";

// Analytics
// https://matteo-gabriele.gitbook.io/vue-gtag/
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: config.gtag }
  },
  router
);

// Sentry
import { initSentry } from "./plugins/sentry";
initSentry(router);

// Font awesome icons
import { initIconLibrary } from "@/plugins/icons";
initIconLibrary();

// Click outside plugin
// https://github.com/ndelvalle/v-click-outside
const vClickOutside = require("v-click-outside");
Vue.use(vClickOutside);

// Hotkey plugin
// https://github.com/Dafrok/v-hotkey
const VueHotKey = require("v-hotkey");

// Tooltip plugin
// https://github.com/Akryum/v-tooltip
import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultHtml: false,
  defaultDelay: 500
});

// Keycode list:
// https://github.com/Dafrok/v-hotkey/blob/master/src/keycodes/codes.js
Vue.use(VueHotKey.default, {
  "←": 37,
  "↑": 38,
  "→": 39,
  "↓": 40
});

// Prism component
const Prism = require("vue-prism-component");
Vue.component("prism", Prism);

// Prism JS and themes
import "prismjs";

Vue.config.productionTip = false;

// Track page visibility
import * as pagevis from "./plugins/pagevis";

// Enable performance tracing in dev
if (process.env.NODE_ENV !== "production") {
  Vue.config.devtools = true;
  Vue.config.performance = true;
}

// Enable v-custom-title directive
Vue.directive("custom-title", (el, binding) => {
  document.title = binding.value ?? "CodeApprove";
});

// Import app and initialize
import App from "./App.vue";
import { config } from "./plugins/config";
new Vue({
  created: () => {
    pagevis.trackVisibility();
  },
  render: h => h(App),
  router,
  store
}).$mount("#app");
