


























































import { Component, Vue, Prop } from "vue-property-decorator";
import * as parseDiff from "parse-diff";

@Component
export default class ChunkHeaderBar extends Vue {
  @Prop({ default: "full" }) mode!: "full" | "end";
  @Prop() prev!: parseDiff.Chunk;
  @Prop() chunk!: parseDiff.Chunk;
  @Prop({ default: false }) readOnly!: boolean;
  @Prop({ default: true }) expandEnabled!: boolean;

  get isEnd() {
    return this.mode === "end";
  }

  get text() {
    if (this.isEnd) {
      return "";
    }

    let leftText = "none";
    if (this.chunk.oldStart > 1) {
      const leftStart = this.prev.oldStart + this.prev.oldLines;
      const leftEnd = this.chunk.oldStart - 1;
      leftText =
        leftStart === leftEnd ? `${leftStart}` : `${leftStart}-${leftEnd}`;
    }

    let rightText = "none";
    if (this.chunk.newStart > 1) {
      const rightStart = this.prev.newStart + this.prev.newLines;
      const rightEnd = this.chunk.newStart - 1;
      rightText =
        rightStart === rightEnd ? `${rightStart}` : `${rightStart}-${rightEnd}`;
    }

    return `> hidden: L${leftText}, L${rightText}`;
  }

  get numHidden() {
    const numLeftHidden =
      this.chunk.oldStart - (this.prev.oldStart + this.prev.oldLines - 1);
    const numRightHidden =
      this.chunk.newStart - (this.prev.newStart + this.prev.newLines - 1);
    return Math.max(numLeftHidden, numRightHidden);
  }

  get isSmallChunk() {
    return this.numHidden < 20;
  }

  get showAbove() {
    if (this.readOnly) {
      return false;
    }
    return !this.isEnd && this.chunk.oldStart > 1 && this.chunk.newStart > 1;
  }

  get showBelow() {
    if (this.readOnly) {
      return false;
    }
    return (
      this.isEnd ||
      (this.prev.oldStart > 0 && this.prev.newStart > 0 && !this.isSmallChunk)
    );
  }

  public expandAbove() {
    if (!this.expandEnabled) {
      return;
    }

    this.$emit("expand-above");
  }

  public expandBelow() {
    if (!this.expandEnabled) {
      return;
    }

    this.$emit("expand-below");
  }
}
