





























































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { functions } from "@/plugins/firebase";
import { httpsCallable } from "@firebase/functions";

import UIModule from "../../store/modules/ui";
import { SignUpRequest } from "../../../../shared/types";

@Component({
  components: {}
})
export default class Interest extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public email = "";
  public companyName = "";
  public githubUrl = "";

  public submitted = false;

  async mounted() {}

  get title() {
    return "Sign Up";
  }

  async submit() {
    if (!this.email) {
      return;
    }

    const req: SignUpRequest = {
      email: this.email,
      company_name: this.companyName,
      github_url: this.githubUrl
    };

    // Submit the request
    this.uiModule.beginLoading();
    const addRequest = httpsCallable<SignUpRequest, {}>(
      functions(),
      "api/addRequest"
    );
    await addRequest(req);

    // Show the "thanks" page
    this.submitted = true;
    this.uiModule.endLoading();
  }
}
