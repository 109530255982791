









































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InboxItem from "@/components/elements/InboxItem.vue";

import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";
import InboxModule from "../../store/modules/inbox";

import { config } from "../../plugins/config";
import { itemSlug } from "../../model/inbox";
import { TraceMethod } from "../../plugins/perf";

import { Github } from "../../../../shared/github";
import { Review } from "../../../../shared/types";
import { Latch } from "../../../../shared/asyncUtils";

@Component({
  components: {
    InboxItem
  }
})
export default class Inbox extends Vue {
  private authModule = getModule(AuthModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);
  private inboxModule = getModule(InboxModule, this.$store);

  private github: Github = new Github(
    AuthModule.getDelegate(this.authModule),
    config.github
  );

  @TraceMethod("Inbox#mounted")
  public async mounted() {
    this.uiModule.beginLoading();

    const installationsPromise = this.inboxModule.loadInstallations({
      github: this.github
    });

    const login = this.authModule.assertUser.username;
    const inboxPromise = this.inboxModule.initialize({
      github: this.github,
      login
    });

    await new Latch([installationsPromise, inboxPromise]).wait();

    this.uiModule.endLoading();
  }

  get title() {
    return "Inbox";
  }

  get hasInstallation() {
    return this.inboxModule.installations.length > 0;
  }

  get reviewSections() {
    return [
      {
        title: "Assigned",
        data: this.inboxModule.assignedReviews,
        empty: "Nothing assigned to you"
      },
      {
        title: "Reviewing",
        data: this.inboxModule.reviewingReviews,
        empty: "Nothing to review"
      },
      {
        title: "Cc'ed",
        data: this.inboxModule.ccedReviews,
        empty: "Not cc'ed on any reviews"
      },
      {
        title: "Outgoing",
        data: this.inboxModule.outgoingReviews,
        empty: "No outgoing reviews"
      },
      {
        title: "Completed",
        data: this.inboxModule.finished,
        empty: "No completed reviews"
      }
    ];
  }

  get hasReviews() {
    return !this.reviewSections.every(s => s.data.length === 0);
  }

  get loaded() {
    return !!this.authModule.user && !this.uiModule.loading;
  }

  get installUrl() {
    return config.github.app_url + "/installations/new";
  }

  public itemKey(review: Review) {
    return itemSlug(review);
  }
}
