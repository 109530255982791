import { fetchAndActivate, getValue } from "firebase/remote-config";

import { remoteConfig } from "./firebase";

export const FLAGS = {
  ENABLE_CHUNKS: "enable_chunks",
  ENABLE_DIFF_SERVER: "enable_diff_server",

  /** When true: require an invite to sign in */
  ENABLE_REQUIRE_INVITE: "enable_require_invite"
};

function hasConfigEntry(key: string) {
  const rc = remoteConfig();
  const value = getValue(rc, key);

  return value.getSource() !== "static";
}

export async function syncFlags() {
  try {
    await fetchAndActivate(remoteConfig());
  } catch (e) {
    console.warn("syncFlags: failed to fetch config", e);
    return;
  }

  const vals: Record<string, any> = {};
  for (const key of Object.values(FLAGS)) {
    const val = getBooleanFlag(key);
    vals[key] = val;
  }

  console.log("Flags");
  console.log(JSON.stringify(vals, undefined, 2));
}

export function getBooleanFlag(key: string, defaultValue?: boolean): boolean {
  const rc = remoteConfig();
  if (!hasConfigEntry(key)) {
    return defaultValue || false;
  }

  const value = getValue(rc, key);
  return value.asBoolean();
}
