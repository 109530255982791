import { LineLocator } from "../../../shared/types";
import { hashCode } from "./hash";

export type EncodedLineLocator = {
  /** 7-char short SHA */
  c: string;

  /** File basename (ex: package.json) */
  n: string;

  /** Full file path hashed with hashCode */
  f: string;

  /** Line number */
  l: number;
};

/**
 * Get the file name without the leading path.
 */
export function getFileName(path: string) {
  const segments = path.split("/");
  return segments[segments.length - 1];
}

/**
 * Lossy encode a LineLocator into a small near-constant-length string for use in URL parameters
 */
export function encodeLocator(locator: LineLocator): string {
  const obj: EncodedLineLocator = {
    c: locator.commit.substring(0, 8),
    n: getFileName(locator.file),
    f: hashCode(locator.file),
    l: locator.line
  };

  return btoa(JSON.stringify(obj));
}

export function decodeLocator(locator: string): EncodedLineLocator | undefined {
  try {
    return JSON.parse(atob(locator));
  } catch {
    console.error("Could not parse locator", locator);
    return undefined;
  }
}
