









































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import RepoSearchModal from "@/components/elements/RepoSearchModal.vue";
import InboxItem from "@/components/elements/InboxItem.vue";

import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";
import BrowseModule from "../../store/modules/browse";

import { doc, getDoc } from "firebase/firestore";

import { TraceMethod } from "../../plugins/perf";
import { firestore } from "../../plugins/firebase";

import { itemSlug } from "../../model/inbox";

import { Github, RepoSearchItem } from "../../../../shared/github";
import { repoPath, reviewsPath } from "../../../../shared/database";
import { Review, UserFavoriteRepo } from "../../../../shared/types";

@Component({
  components: {
    InboxItem,
    RepoSearchModal
  }
})
export default class Browse extends Vue {
  private authModule = getModule(AuthModule, this.$store);
  private browseModule = getModule(BrowseModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);

  public searching = false;

  @TraceMethod("Browse#mounted")
  public async mounted() {
    this.uiModule.beginLoading();

    const id = this.authModule.assertUser.uid;
    await this.browseModule.initialize({ id });

    this.uiModule.endLoading();
  }

  public async onRepoSelected(e: { item: RepoSearchItem }) {
    console.log(`onRepoSelected(${JSON.stringify(e.item)})`);
    this.searching = false;

    const { owner, name, full_name } = e.item;

    // Check if it's on CA and the user can access it
    const repoRef = doc(firestore(), repoPath({ owner, repo: name }));
    try {
      const repo = await getDoc(repoRef);
      if (!repo.exists()) {
        this.uiModule.addDisappearingError(
          `Sorry, "${full_name}" is not yet on CodeApprove.`
        );
        return;
      }
    } catch (e) {
      this.uiModule.addDisappearingError(
        `Sorry, "${full_name}" is not on CodeApprove or you do not have access.`
      );
      return;
    }

    // Add to their favorites list
    // TODO(polish): Would be good to have a loading animation here
    await this.browseModule.addFavoriteRepo({ owner, name });
  }

  public async removeFavorite(repo: UserFavoriteRepo) {
    await this.browseModule.removeFavoriteRepo(repo);
  }

  get loaded() {
    return !!this.authModule.user;
  }

  get reviews() {
    return this.browseModule.recentReviews;
  }

  get favoriteRepos() {
    return this.browseModule.favoriteRepos;
  }

  get title() {
    return "Browse";
  }

  public itemKey(review: Review) {
    return itemSlug(review);
  }
}
