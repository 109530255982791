import { User } from "@/model/auth";

declare var posthog: {
  reset: () => void;
  identify: (
    id: string,
    properties?: {
      email?: string;
      name?: string;
    }
  ) => void;
};

export function identify(user: User | null) {
  try {
    if (!user) {
      posthog.reset();
      return;
    }

    posthog.identify(user.uid, { email: user.email, name: user.username });
  } catch (e) {
    console.log("Failed to identify", e);
  }
}
