import Vue from "vue";
import VueRouter from "vue-router";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export function initSentry(router: VueRouter) {
  Sentry.init({
    Vue,
    dsn:
      "https://a20fa708661b4d78a5f0018968bf7e6c@o4504165306335232.ingest.sentry.io/4504165322194944",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "codeapprove.com", /^\//]
      })
    ],

    // Dev vs Prod
    environment:
      process.env.NODE_ENV === "production" ? "production" : "development",

    // Sample 5% of sessions
    tracesSampleRate: 0.05,

    // Make sure vue still logs errors to the console
    logErrors: true
  });
}

export function logError(
  e: any,
  msg: string,
  tags: Record<string, string | number> = {}
) {
  // Set tags
  Object.entries(tags).map(e => Sentry.setTag(e[0], e[1]));

  // Log message
  console.warn(msg);

  // Log exception
  Sentry.captureException(e);
}
