












import { Component, Vue, Prop } from "vue-property-decorator";

import MarkdownContent from "@/components/elements/MarkdownContent.vue";

@Component({
  components: {
    MarkdownContent
  }
})
export default class PrivacyPolicy extends Vue {
  public content = "";

  async mounted() {
    const res = await fetch("/policies/privacy.md");
    this.content = await res.text();
  }

  get title() {
    return "Privacy Policy";
  }
}
