




















import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";

@Component({
  components: {}
})
export default class SignOut extends Vue {
  private uiModule = getModule(UIModule, this.$store);
  private authModule = getModule(AuthModule, this.$store);

  get title() {
    return "Sign Out";
  }

  async mounted() {
    this.uiModule.beginLoading();

    try {
      await this.authModule.startSignOut();
      this.$router.push("/signin");
    } catch (e) {
      console.warn("Failed to sign out", e);
      this.uiModule.addDisappearingError(
        "There was an issue signing out. Please report this using the feedback link below!"
      );
    }

    this.uiModule.endLoading();
  }
}
