









































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import HeaderBar from "@/components/elements/HeaderBar.vue";
import FooterBar from "@/components/elements/FooterBar.vue";
import ProgressBar from "@/components/elements/ProgressBar.vue";
import MessageStack from "@/components/elements/MessageStack.vue";

import { Unsubscribe, onAuthStateChanged } from "firebase/auth";
import {
  fetchAndActivate,
  getNumber,
  isSupported
} from "firebase/remote-config";

import { auth, performance, remoteConfig } from "./plugins/firebase";
import { config } from "./plugins/config";
import * as events from "./plugins/events";
import { syncFlags } from "./plugins/flags";
import { LocalSettings } from "./plugins/localSettings";

import AuthModule from "./store/modules/auth";
import BrowseModule from "./store/modules/browse";
import ReviewModule from "./store/modules/review";
import InboxModule from "./store/modules/inbox";
import UIModule from "./store/modules/ui";

@Component({
  components: {
    HeaderBar,
    FooterBar,
    ProgressBar,
    MessageStack
  }
})
export default class App extends Vue {
  authModule = getModule(AuthModule, this.$store);
  browseModule = getModule(BrowseModule, this.$store);
  inboxModule = getModule(InboxModule, this.$store);
  reviewModule = getModule(InboxModule, this.$store);
  uiModule = getModule(UIModule, this.$store);

  authUnsub?: Unsubscribe = undefined;

  created() {
    // Initialize Firebase Performance (built-in tracing)
    performance();

    // Sync RC flags
    syncFlags();

    // TODO(polish): This should be in the auth module itself
    this.authUnsub = onAuthStateChanged(auth(), user => {
      console.log(`onAuthStateChanged(${user ? user.uid : null})`);
      if (!user) {
        this.stopListening();
        this.authModule.setUser(null);
      }
    });

    // Check for min version on create and on visibility change
    this.checkMinVersion();
    events.on(
      events.PAGE_VISIBILITY_EVENT,
      (evt: events.PageVisibilityEvent) => {
        if (evt.visible) {
          this.checkMinVersion();
        }
      }
    );

    events.on(events.REQUEST_REFRESH_EVENT, () => {
      location.reload();
    });

    events.on(events.CUSTOM_ERROR_EVENT, (e: events.CustomErrorEvent) => {
      this.uiModule.addMessage({
        type: "error",
        text: e.message
      });
    });

    this.setupCodeStyles();
  }

  destroyed() {
    this.authUnsub && this.authUnsub();
  }

  setupCodeStyles() {
    // Import the right prism theme at runtime, Prism only has support
    // for a single theme in the app.
    const themeLink = this.useDarkTheme
      ? "/prism-themes/themes/prism-vsc-dark-plus.css"
      : "/prism-themes/themes/prism-ghcolors.css";

    let el = document.getElementById("code-styles-css");
    if (!el) {
      el = document.createElement("link");
      el.setAttribute("id", "code-styles-css");
      el.setAttribute("rel", "stylesheet");
      document.head.appendChild(el);
    }

    el.setAttribute("href", themeLink);
  }

  get routeIsHome() {
    return this.$route.fullPath === "/";
  }

  get useDarkTheme() {
    if (this.routeIsHome) {
      return true;
    }

    return (
      LocalSettings.getOrDefault(LocalSettings.KEY_THEME, "dark") === "dark"
    );
  }

  public stopListening() {
    this.browseModule.stopListening();
    this.inboxModule.stopListening();
    this.reviewModule.stopListening();
  }

  public async checkMinVersion() {
    const rcSupported = await isSupported();
    if (!rcSupported) {
      console.warn("Remote config is not supported");
      return;
    }

    const rc = remoteConfig();
    try {
      await fetchAndActivate(rc);
      const minVersion = getNumber(rc, "min_version");
      if (config.version !== minVersion) {
        console.log(
          `Current version is ${config.version}, min version is ${minVersion}`
        );
      }

      if (config.version < minVersion) {
        this.uiModule.addMessage({
          type: "blocking_error",
          text:
            "There is a newer version of CodeApprove available, click to refresh.",
          action: events.REQUEST_REFRESH_EVENT
        });
      }
    } catch (e) {
      console.warn("Failed to fetch config", e);
    }
  }
}
