import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import {
  doc,
  collection,
  FieldValue,
  onSnapshot,
  query,
  orderBy,
  limit,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove
} from "firebase/firestore";

import { firestore } from "@/plugins/firebase";

import { Review, UserFavoriteRepo, UserRepos } from "../../../../shared/types";
import { Latch } from "../../../../shared/asyncUtils";
import { reviewsPath, userReposPath } from "../../../../shared/database";

type Listener = () => void;

@Module({
  namespaced: true,
  name: "browse"
})
export default class BrowseModule extends VuexModule {
  public id: string = "";
  public listeners: Listener[] = [];

  public allReviews: Review[] = [];
  public userRepos: UserRepos = {
    repo_names: []
  };

  @Action({ rawError: true })
  public async initialize(opts: { id: string }) {
    this.context.commit("stopListening");
    this.context.commit("setId", opts.id);

    const latch = new Latch();

    // TODO(polish): Make this screen realtime
    latch.increment();
    const userReposUnsub = onSnapshot(
      doc(firestore(), userReposPath(opts)),
      async snap => {
        const data = snap.data() as UserRepos;
        this.context.commit("setUserRepos", data);
        this.context.commit("setAllReviews", []);

        // Get the last 10 reviews from each favorite
        const favorites = data.favorite_repos || [];
        for (const r of favorites) {
          const reviewsRef = collection(
            firestore(),
            reviewsPath({ owner: r.owner, repo: r.name })
          );
          const lastTen = query(
            reviewsRef,
            orderBy("metadata.updated_at", "desc"),
            limit(10)
          );

          const snap = await getDocs(lastTen);
          const reviews = snap.docs.map(d => d.data() as Review);
          this.context.commit("addReviews", reviews);
        }

        latch.decrement();
      }
    );
    this.context.commit("addListener", userReposUnsub);

    return latch.wait();
  }

  @Action({ rawError: true })
  public async addFavoriteRepo(opts: { owner: string; name: string }) {
    const ref = doc(firestore(), userReposPath({ id: this.id }));

    const item: UserFavoriteRepo = {
      owner: opts.owner,
      name: opts.name
    };
    await updateDoc(ref, {
      favorite_repos: arrayUnion(item)
    });
  }

  @Action({ rawError: true })
  public async removeFavoriteRepo(opts: { owner: string; name: string }) {
    const ref = doc(firestore(), userReposPath({ id: this.id }));

    const item: UserFavoriteRepo = {
      owner: opts.owner,
      name: opts.name
    };
    await updateDoc(ref, {
      favorite_repos: arrayRemove(item)
    });
  }

  @Mutation
  public setId(id: string) {
    this.id = id;
  }

  @Mutation
  public setUserRepos(userRepos: UserRepos) {
    this.userRepos = userRepos;
  }

  @Mutation
  public setAllReviews(allReviews: Review[]) {
    this.allReviews = allReviews;
  }

  @Mutation
  public addReviews(reviews: Review[]) {
    this.allReviews.push(...reviews);
  }

  @Mutation
  public addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  @Mutation
  public stopListening() {
    for (const l of this.listeners) {
      l();
    }

    this.allReviews = [];
    this.listeners = [];
  }

  get favoriteRepos(): UserFavoriteRepo[] {
    return this.userRepos.favorite_repos || [];
  }

  get recentReviews(): Review[] {
    return this.allReviews
      .sort((a, b) => {
        return b.metadata.updated_at - a.metadata.updated_at;
      })
      .slice(0, 25);
  }
}
