







































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const { Mentionable } = require("../../plugins/vue-mention");

import Avatar from "@/components/elements/Avatar.vue";
import MarkdownContent from "@/components/elements/MarkdownContent.vue";
import ProgressBar from "@/components/elements/ProgressBar.vue";

import UIModule from "../../store/modules/ui";
import AuthModule from "../../store/modules/auth";

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { config } from "../../plugins/config";
import { storage } from "../../plugins/firebase";
import { searchEmojiNames, getEmojiByName } from "../../plugins/emoji";
import { getCtrlKeyName, RICH_TEXT_AREA_KEY_MAP } from "@/plugins/hotkeys";
import { RichTextAreaAPI } from "../api";
import { getExt } from "@/plugins/binary";

import { Github } from "../../../../shared/github";

@Component({
  components: {
    MarkdownContent,
    ProgressBar,
    Mentionable,
    Avatar
  }
})
export default class RichTextArea extends Vue implements RichTextAreaAPI {
  @Prop() value!: string;
  @Prop() placeholder!: string;
  @Prop() rows!: string;
  @Prop({ default: false }) focusOnMount!: boolean;

  @Prop({ default: null }) owner!: string | null;
  @Prop({ default: null }) repo!: string | null;

  uiModule = getModule(UIModule, this.$store);
  private github!: Github;
  private authModule = getModule(AuthModule, this.$store);

  fieldFocus = false;
  dragFocus = false;
  uploadingImage = false;
  renderDraft = false;

  suggestionsKey = "@";
  suggestionsLoading = false;
  suggestionsSearch = "";
  suggestions: {
    value: string;
    label: string;
    sublabel?: string;
    icon?: string;
  }[] = [];

  // Options passed from vue-mention to v-tooltip to popper.js
  // See: https://popper.js.org/docs/v1/
  //
  // TODO: Suppress this message from popper.js
  // `preventOverflow` modifier is required by `hide` modifier in order to work, be sure to include it before `hide`!
  mentionablePopperOptions = {
    positionFixed: true,
    modifiers: {
      flip: { enabled: false },
      preventOverflow: { enabled: false },
      hide: { enabled: true }
    }
  };

  mounted() {
    this.github = new Github(
      AuthModule.getDelegate(this.authModule),
      config.github
    );

    if (this.focusOnMount) {
      const ta = this.$el.querySelector("textarea");
      if (ta) {
        ta.focus();
      }
    }
  }

  /**
   * See: https://zaengle.com/blog/using-v-model-on-nested-vue-components
   */
  get draftComment() {
    return this.value;
  }

  set draftComment(value: string) {
    this.$emit("input", value);
  }

  public focusForm() {
    this.replyField().focus();
  }

  public resetMentionable() {
    this.suggestions = [];
    this.suggestionsLoading = false;
    this.suggestionsSearch = "";
  }

  public onMentionableOpen(key: string) {
    this.suggestionsKey = key;
    this.resetMentionable();
  }

  public async onMentionableSearch(searchText: string | null) {
    if (searchText === null) {
      return;
    }
    this.suggestionsSearch = searchText;

    // Emoji search is simple and sync, no need to show loading state
    if (this.suggestionsKey === ":") {
      const emojis = searchEmojiNames(searchText).slice(0, 10);
      this.suggestions = emojis.map(e => {
        const unicode = getEmojiByName(e);

        // The split-replace is a hack to do "replaceAll"
        const label = e.split("_").join(" ");
        return {
          value: unicode,
          label: `${unicode} ${label}`
        };
      });

      return;
    }

    // The other searches are async
    this.suggestionsLoading = true;

    try {
      if (this.suggestionsKey === "@") {
        this.suggestions = await this.getUserSuggestions(searchText);
      }

      if (this.suggestionsKey === "#") {
        this.suggestions = await this.getIssueSuggestions(searchText);
      }
    } catch (e) {
      console.warn(e);
    }

    this.suggestionsLoading = false;
  }

  get mentionableOmitKey() {
    return this.suggestionsKey === ":" || this.suggestionsKey === "#";
  }

  private async getUserSuggestions(searchText: string) {
    const users = await this.github.searchUsers(
      this.owner,
      this.repo,
      searchText || "",
      true
    );

    return users.map(u => {
      return {
        value: u.login,
        label: u.login,
        sublabel: u.name || undefined
      };
    });
  }

  private async getIssueSuggestions(searchText: string) {
    if (!this.owner || !this.repo) {
      return [];
    }

    const search = parseInt(searchText, 10);
    if (isNaN(search)) {
      return [];
    }

    const issue = await this.github.getIssueOrPullRequest(
      this.owner,
      this.repo,
      search
    );
    const items = issue ? [issue] : [];

    return items.map(r => {
      const icon = r.pull_request ? "code-branch" : "circle-exclamation";
      return {
        icon,
        label: `#${r.number} ${r.title}`,
        value: r.url
      };
    });
  }

  public async onTextPaste(e: ClipboardEvent) {
    if (!e.clipboardData) {
      return;
    }

    // When a link is pasted over text, linkify (if it is a link)
    const plainText = e.clipboardData.getData("text/plain");
    if (typeof plainText === "string" && this.hasSelection()) {
      if (plainText && plainText.startsWith("http")) {
        this.link(e, plainText);
      }
    }

    // Handle file pastes
    for (const c of e.clipboardData.items) {
      if (c.kind === "file" && this.isTypeSupported(c.type)) {
        const file = c.getAsFile();
        if (file) {
          await this.addFile(file);
        }
      }
    }
  }

  public async onFileSelected(e: Event) {
    // Keep focus in the form
    this.focusForm();

    if (e.target != null) {
      const files = (e.target as HTMLInputElement).files;
      if (!files || files.length <= 0) {
        return;
      }

      for (const file of files) {
        await this.addFile(file);
      }
    }
  }

  public async onFileDropped(e: Event) {
    this.focusForm();
    this.dragFocus = false;

    const data = (e as DragEvent).dataTransfer;
    if (!data || data.files.length <= 0) {
      return;
    }

    // Prevent the browser from handling the file in any way
    // since we intend to upload it.
    e.preventDefault();

    for (const file of data.files) {
      if (!this.isTypeSupported(file.type)) {
        this.uiModule.addDisappearingError(
          `Error: unsupported file type ${file.type}, only image/video files are allowed.`
        );
        continue;
      }

      await this.addFile(file);
    }
  }

  private isTypeSupported(fileType: string): boolean {
    return (
      fileType.startsWith("image/") ||
      fileType === "video/mp4" ||
      fileType === "video/quicktime"
    );
  }

  private async uploadFile(file: File): Promise<string | undefined> {
    // Separate the file extension
    const ext = getExt(file.name);

    // 1. Need to replace all Unicode chars to make btoa() work
    // 2. The split-replace is a hack to do "replaceAll"
    const preSlug = `${new Date().getTime()}-${file.name}`;

    // eslint-disable-next-line no-control-regex
    const slug = btoa(preSlug.replace(/[^\x00-\x7F]/g, ""))
      .split("=")
      .join("");

    const name = `${slug}.${ext}`;
    console.log("Uploading", file.name, name);

    this.uploadingImage = true;
    try {
      const uploadRef = ref(storage(), name);
      const res = await uploadBytes(uploadRef, file);
      const url = await getDownloadURL(res.ref);
      this.uploadingImage = false;
      return url;
    } catch (e) {
      console.warn("Failed to upload attachment", e);
      this.uiModule.addDisappearingError("Attachment upload failed.");
      this.uploadingImage = false;
    }

    return undefined;
  }

  private async addFile(file: File) {
    if (file.type.startsWith("image/")) {
      return this.addImage(file);
    }

    if (file.type.startsWith("video/")) {
      return this.addVideo(file);
    }
  }

  private async addImage(file: File) {
    const url = await this.uploadFile(file);
    if (url) {
      const link = `![${file.name}](${url}) `;
      const linkWithNewlines = `\n${link}\n`;
      this.insertAtSelection(linkWithNewlines);
      this.moveCursorBy(linkWithNewlines.length);
    }
  }

  private async addVideo(file: File) {
    const url = await this.uploadFile(file);
    if (url) {
      // Videos are just added as links, the markdown parser handles them
      const link = `[${file.name}](${url}) `;
      this.insertAtSelection(link);
      this.moveCursorBy(link.length);
    }
  }

  public insertAtSelection(val: string) {
    const field = this.replyField();
    field.setRangeText(val);
    this.draftComment = field.value;
  }

  public bold(e?: Event) {
    this.transformSelection(s => {
      const val = `**${s}**`;
      const cursor = 2 + s.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public italic(e?: Event) {
    this.transformSelection(s => {
      const val = `_${s}_`;
      const cursor = 1 + s.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public inlineCode(e?: Event) {
    this.transformSelection(s => {
      const val = `\`${s}\``;
      const cursor = 1 + s.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public onKeyDown(e: KeyboardEvent) {
    // When text is selected, pressing ` will surround it
    if (e.key === "`" && this.hasSelection()) {
      e.preventDefault();
      this.inlineCode();
    }
  }

  public link(e?: Event, url: string = "") {
    this.transformSelection(s => {
      const val = `[${s}](${url})`;
      const cursor = 3 + s.length + url.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public quote(e?: Event) {
    this.transformSelection(s => {
      const lines = s.split("\n");
      const quote = lines.map(l => `> ${l}`);
      const val = "\n" + quote.join("\n");
      const cursor = s.length + 1 + 3 * lines.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public code(e?: Event) {
    this.transformSelection(s => {
      const val = `\`${s}\``;
      const cursor = 1 + s.length;

      return { val, cursor };
    });

    if (e) {
      e.preventDefault();
    }
  }

  public submit(e?: Event) {
    this.$emit("submit");
  }

  public hasSelection() {
    const field = this.replyField();

    const start = field.selectionStart || 0;
    const end = field.selectionEnd || 0;

    return start !== end;
  }

  public transformSelection(
    fn: (s: string) => { val: string; cursor: number }
  ) {
    const field = this.replyField();

    const start = field.selectionStart || 0;
    const end = field.selectionEnd;
    const selection = end != null ? field.value.substring(start, end) : "";
    const newSelection = fn(selection);

    // Replace the text
    field.setRangeText(newSelection.val);

    // Move the cursor
    this.moveCursorBy(newSelection.cursor);

    this.draftComment = field.value;
  }

  public insertText(val: string) {
    const field = this.replyField();
    field.setRangeText(val);

    this.moveCursorBy(val.length);

    this.draftComment = field.value;
  }

  public moveCursorBy(diff: number) {
    const field = this.replyField();
    const start = field.selectionStart || 0;
    const newCursor = start + diff;

    field.focus();
    field.setSelectionRange(newCursor, newCursor);
  }

  get hasDraft() {
    return this.draftComment.length > 0;
  }

  get showMd() {
    return this.hasDraft && this.renderDraft;
  }

  get hotKeyMap() {
    return RICH_TEXT_AREA_KEY_MAP(this);
  }

  public shortcutDesc(key: string) {
    return `${getCtrlKeyName()}+${key}`;
  }

  public replyField() {
    return this.$el.querySelector("textarea") as HTMLTextAreaElement;
  }
}
