import {
  ChangeEntryAPI,
  PullRequestAPI,
  CommentThreadAPI,
  HotkeyModalAPI,
  RichTextAreaAPI
} from "../components/api";

export type KeyActionMap = Record<string, KeyAction>;
export type KeyDescMap = Record<string, KeyDesc>;
export type KeyMap = Record<string, KeyBinding>;

export interface KeyDesc {
  desc: string;
}

export type KeyAction = {
  keydown: Function;
  keyup?: Function;
};

export type KeyBinding = KeyDesc & KeyAction;

function wrapCallback(fn: Function, allowInTextFields: boolean = false) {
  return (e: Event) => {
    let inEditableField = false;
    if (document && document.activeElement) {
      const tagName = document.activeElement.tagName;
      inEditableField =
        tagName === "INPUT" || tagName === "TEXTAREA" || tagName === "SELECT";
    }

    if (!inEditableField || allowInTextFields) {
      fn(e);
    }
  };
}

export const HOTKEY_MODAL_KEY_DESC: KeyDescMap = {
  "shift+/": {
    desc: "Show the list of shortcuts."
  }
};

export const HOTKEY_MODAL_KEY_MAP = (instance: HotkeyModalAPI) => {
  const actionMap: KeyActionMap = {
    "shift+/": {
      keydown: wrapCallback(instance.onShow)
    }
  };

  return zip(HOTKEY_MODAL_KEY_DESC, actionMap);
};

export const PULL_REQUEST_KEY_DESC: KeyDescMap = {
  "j,↓": {
    desc: "Next line or file."
  },
  "k,↑": {
    desc: "Previous line or file"
  },
  "shift+j": {
    desc: "Jump to next file."
  },
  "shift+k": {
    desc: "Jump to previous file."
  },
  "l,→": {
    desc: "Expand selected file"
  },
  "h,←": {
    desc: "Collapse selected file"
  },
  enter: {
    desc: "Toggle expand/collapse selected file."
  },
  e: {
    desc: "Toggle selected file reviewed state."
  }
};

export const PULL_REQUEST_KEY_MAP = (instance: PullRequestAPI) => {
  const actionMap: KeyActionMap = {
    "j,↓": {
      keydown: wrapCallback(instance.onNextFileOrLine)
    },
    "k,↑": {
      keydown: wrapCallback(instance.onPrevFileOrLine)
    },
    "shift+j": {
      keydown: wrapCallback(instance.onNextFile)
    },
    "shift+k": {
      keydown: wrapCallback(instance.onPrevFile)
    },
    "l,→": {
      keydown: wrapCallback(() => instance.onToggleFile(true))
    },
    "h,←": {
      keydown: wrapCallback(() => instance.onToggleFile(false))
    },
    enter: {
      keydown: wrapCallback(instance.onToggleFile)
    },
    e: {
      keydown: wrapCallback(instance.onToggleFileReviewed)
    }
  };

  return zip(PULL_REQUEST_KEY_DESC, actionMap);
};

export const CHANGE_ENTRY_KEY_DESC: KeyDescMap = {
  c: {
    desc: "Add comment to current line."
  }
};

export const CHANGE_ENTRY_KEY_MAP = (instance: ChangeEntryAPI) => {
  const actionMap: KeyActionMap = {
    c: {
      keydown: wrapCallback(instance.addLineComment)
    }
  };

  return zip(CHANGE_ENTRY_KEY_DESC, actionMap);
};

export const COMMENT_THREAD_KEY_DESC: KeyDescMap = {
  [ctrlPlus("enter")]: {
    desc: "Send comment."
  },
  [ctrlPlus("shift+enter")]: {
    desc: "Send comment and resolve thread."
  }
};

export const COMMENT_THREAD_KEY_MAP = (instance: CommentThreadAPI) => {
  const actionMap: KeyActionMap = {
    [ctrlPlus("enter")]: {
      keydown: wrapCallback(() => instance.addComment(), true)
    },
    [ctrlPlus("shift+enter")]: {
      keydown: wrapCallback(() => instance.addComment(true), true)
    }
  };

  return zip(COMMENT_THREAD_KEY_DESC, actionMap);
};

export const RICH_TEXT_AREA_KEY_DESC: KeyDescMap = {
  [ctrlPlus("b")]: {
    desc: "Bold"
  },
  [ctrlPlus("i")]: {
    desc: "Italicize"
  },
  [ctrlPlus("k")]: {
    desc: "Hyperlink"
  },
  [ctrlPlus("enter")]: {
    desc: "Submit"
  }
};

export const RICH_TEXT_AREA_KEY_MAP = (instance: RichTextAreaAPI) => {
  const actionMap: KeyActionMap = {
    [ctrlPlus("b")]: {
      keydown: instance.bold
    },
    [ctrlPlus("i")]: {
      keydown: instance.italic
    },
    [ctrlPlus("k")]: {
      keydown: instance.link
    },
    [ctrlPlus("enter")]: {
      keydown: instance.submit
    }
  };

  return zip(RICH_TEXT_AREA_KEY_DESC, actionMap);
};

export function combineHotkeys(...maps: KeyDescMap[]): KeyDescMap {
  const res: KeyDescMap = {};

  for (const map of maps) {
    for (const key in map) {
      res[key] = map[key];
    }
  }

  return res;
}

function isApple() {
  return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
}

export function getCtrlKeyName() {
  if (isApple()) {
    return "⌘";
  } else {
    return "ctrl";
  }
}

export function getCtrlKeyCode() {
  if (isApple()) {
    return "meta";
  } else {
    return "ctrl";
  }
}

function ctrlPlus(combo: string) {
  return `${getCtrlKeyCode()}+${combo}`;
}

function splitKeys<T>(dict: Record<string, T>): Record<string, T> {
  const res: Record<string, T> = {};

  for (const key in dict) {
    const split = key.split(",");
    for (const splitKey of split) {
      res[splitKey] = dict[key];
    }
  }

  return res;
}

function zip(desc: KeyDescMap, action: KeyActionMap): KeyMap {
  let d = splitKeys(desc);
  let a = splitKeys(action);

  const res: KeyMap = {};

  for (const key in d) {
    res[key] = {
      keydown: a[key].keydown,
      keyup: a[key].keyup,
      desc: d[key].desc
    };
  }

  return res;
}
