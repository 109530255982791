import * as uuid from "uuid";

import { Thread } from "../../../shared/types";

export interface SidePair<T> {
  left: T;
  right: T;
}

export interface LangPair extends SidePair<string> {}

export interface ThreadPair extends SidePair<Thread | null> {}

export function newRandomId() {
  return uuid.v4();
}
