import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";
import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions
} from "firebase/functions";
import { FirebasePerformance, getPerformance } from "firebase/performance";
import { RemoteConfig, getRemoteConfig } from "firebase/remote-config";
import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage
} from "firebase/storage";

import { config } from "./config";

let _auth: Auth | undefined = undefined;
let _functions: Functions | undefined = undefined;

const authOptions = {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence
  ]
};

export function auth(): Auth {
  if (!_auth) {
    _auth = initializeAuth(app(), authOptions);
  }

  return _auth;
}

export function firestore(): Firestore {
  return getFirestore(app());
}

export function storage(): FirebaseStorage {
  return getStorage(app());
}

export function functions(): Functions {
  if (_functions === undefined) {
    const host =
      process.env.NODE_ENV !== "production"
        ? "http://localhost:5000"
        : "https://codeapprove.com";

    _functions = getFunctions(app(), host);
  }

  return _functions;
}

export function performance(): FirebasePerformance {
  return getPerformance(app());
}

export function remoteConfig(): RemoteConfig {
  const rc = getRemoteConfig(app());

  // In development always refresh the RC cache, in production every 30m
  if (process.env.NODE_ENV !== "production") {
    rc.settings.minimumFetchIntervalMillis = 0;
  } else {
    rc.settings.minimumFetchIntervalMillis = 30 * 60 * 1000;
  }

  return rc;
}

function app(): FirebaseApp {
  if (getApps().length === 0) {
    const app = initializeApp(config.firebase);

    if (process.env.NODE_ENV !== "production") {
      connectAuthEmulator(
        initializeAuth(app, authOptions),
        "http://localhost:9099",
        {
          disableWarnings: true
        }
      );
      connectFirestoreEmulator(getFirestore(app), "localhost", 8040);
      connectFunctionsEmulator(getFunctions(app), "localhost", 5001);
      connectStorageEmulator(getStorage(app), "localhost", 9199);
    }
  }

  return getApp();
}
