




















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import RichTextArea from "@/components/elements/RichTextArea.vue";
import ToggleSwitch from "@/components/elements/ToggleSwitch.vue";

import AuthModule from "../../store/modules/auth";
import ReviewModule from "../../store/modules/review";

import { FinishReviewEvent } from "../../plugins/events";
import { THREAD_LINE_OUTDATED } from "../../../../shared/types";

@Component({
  components: {
    RichTextArea,
    ToggleSwitch
  }
})
export default class FinishReviewModal extends Vue {
  @Prop({ default: null }) owner!: string | null;
  @Prop({ default: null }) repo!: string | null;

  public choice = "comment";

  public draftReviewComment = "";
  public draftReviewCommentResolved = true;

  private authModule = getModule(AuthModule, this.$store);
  private reviewModule = getModule(ReviewModule, this.$store);

  public mounted() {
    this.choice = this.hasApproved ? "approve" : "comment";
  }

  public resetState() {
    this.draftReviewComment = "";
    this.draftReviewCommentResolved = true;
    this.choice = "comment";
  }

  public discardReview() {
    this.resetState();
    this.$emit("discard");
  }

  public sendReview() {
    // Stick with previous approval unless there is an explicit selection
    let approved = this.hasApproved;
    if (this.choice === "approve") {
      approved = true;
    }
    if (this.choice === "undo-approval") {
      approved = false;
    }

    const evt: FinishReviewEvent = {
      approved
    };

    if (this.draftReviewComment.length > 0) {
      evt.comment = {
        text: this.draftReviewComment,
        resolved: this.draftReviewCommentResolved
      };
    }

    this.$emit("send", evt);
    this.resetState();
  }

  get choices() {
    const comment = {
      value: "comment",
      title: "Comment",
      description: "Send comments without approving the review.",
      disabled: false
    };

    const approvedDesc = this.isAuthor
      ? "You cannot approve this review because you are the author."
      : "Give this review your stamp of approval. Unresolved comments must still be addressed.";
    const approve = {
      value: "approve",
      title: "Approve",
      description: approvedDesc,
      disabled: this.isAuthor
    };

    const undoApprovalDesc = this.isAuthor
      ? "You cannot approve this review because you are the author."
      : "Remove your approval of this review.";
    const undoApproval = {
      value: "undo-approval",
      title: "Undo Approval",
      description: undoApprovalDesc,
      disabled: this.isAuthor || !this.hasApproved
    };

    return [comment, approve, undoApproval];
  }

  get drafts() {
    // Show only MY drafts
    return this.reviewModule.drafts.filter(
      t => t.username === this.authModule.assertUser.username
    );
  }

  get numUnresolvedAfter() {
    const { delta } = this.reviewModule.countResolutions;

    return this.reviewModule.review.state.unresolved + delta;
  }

  get numOutdatedDrafts() {
    const username = this.authModule.assertUser.username;
    const threads = this.reviewModule.threads.filter(
      t =>
        t.draft &&
        t.username === username &&
        t.currentArgs.line === THREAD_LINE_OUTDATED
    );

    return threads.length;
  }

  get isAuthor() {
    return (
      this.reviewModule.review.metadata.author ===
      this.authModule.assertUser.username
    );
  }

  get hasApproved() {
    return this.reviewModule.review.state.approvers.includes(
      this.authModule.assertUser.username
    );
  }
}
