




















import { Component, Vue, Prop } from "vue-property-decorator";

import Avatar from "@/components/elements/Avatar.vue";

import { ReviewHistory, ReviewStatus } from "../../../../shared/types";
import * as typeUtils from "../../../../shared/typeUtils";

@Component({
  components: {
    Avatar
  }
})
export default class ReviewLogItem extends Vue {
  @Prop() public history!: ReviewHistory;

  async mounted() {}

  public formatTimestampLong(timestamp: number) {
    return typeUtils.formatTimestampLong(timestamp);
  }

  get displayInfo() {
    const text = typeUtils.getHistorySummaryText(this.history);

    if (typeUtils.historyHasApprovalChange(this.history)) {
      return {
        text,
        icon: {
          name: this.statusIconName,
          class: this.statusClass.text
        }
      };
    }

    if (typeUtils.historyHasCommentActions(this.history)) {
      return {
        text,
        icon: {
          name: "reply"
        }
      };
    }

    if (typeUtils.historyIsReviewCreated(this.history)) {
      return {
        text,
        icon: {
          name: "user"
        }
      };
    }

    return {
      text,
      icon: {
        name: "user"
      }
    };
  }

  get status() {
    return this.history.approval
      ? ReviewStatus.APPROVED
      : ReviewStatus.NEEDS_APPROVAL;
  }

  get statusClass() {
    return typeUtils.statusClass(this.status);
  }

  get statusIconName() {
    return typeUtils.statusIconName(this.status);
  }
}
