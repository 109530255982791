





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Avatar from "@/components/elements/Avatar.vue";

import AuthModule from "../../store/modules/auth";
import { LocalSettings } from "@/plugins/localSettings";
import { getBooleanFlag, FLAGS } from "@/plugins/flags";

@Component({
  components: {
    Avatar
  }
})
export default class HeaderBar extends Vue {
  @Prop({ default: false }) home!: boolean;

  showDropdown = false;

  private authModule = getModule(AuthModule, this.$store);

  public enableRequireInvite = getBooleanFlag(FLAGS.ENABLE_REQUIRE_INVITE);

  get classes() {
    if (this.home) {
      return [];
    }

    return ["bg-app-2"];
  }

  get logoFile() {
    const theme = LocalSettings.getOrDefault(LocalSettings.KEY_THEME, "dark");
    if (theme === "dark") {
      return "/codeapprove-logo.svg";
    } else {
      return "/codeapprove-logo-light.svg";
    }
  }

  get signedIn() {
    return this.authModule.signedIn;
  }

  get username() {
    if (!this.authModule.user) {
      return "unknown";
    }

    return this.authModule.assertUser.username;
  }

  get photoURL() {
    if (!this.authModule.user) {
      return "";
    }

    return this.authModule.assertUser.photoURL;
  }

  get homeURL() {
    if (!this.authModule.user) {
      return "/";
    }

    return "/inbox";
  }

  public async signOut() {
    this.showDropdown = false;
    await this.authModule.startSignOut();
    this.$router.push("/signin");
  }
}
