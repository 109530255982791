






























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Mixins, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import parseDiff from "parse-diff";

import { httpsCallable } from "firebase/functions";

import * as events from "../../plugins/events";

import ChangeEntry, { type ChangeEntryLineClickEvent } from "@/components/elements/ChangeEntry.vue";
import CommentThread from "@/components/elements/CommentThread.vue";
import MarkdownContent from "@/components/elements/MarkdownContent.vue";
import UserSearchModal from "@/components/elements/UserSearchModal.vue";
import HotkeyModal from "@/components/elements/HotkeyModal.vue";
import LabeledSelect from "@/components/elements/LabeledSelect.vue";
import SimpleLabeledSelect from "@/components/elements/SimpleLabeledSelect.vue";
import CommitSelectItem from "@/components/elements/CommitSelectItem.vue";
import TwoLineSelectItem from "@/components/elements/TwoLineSelectItem.vue";
import UserReviewIcon from "@/components/elements/UserReviewIcon.vue";
import ReviewLogItem from "@/components/elements/ReviewLogItem.vue";
import Avatar from "@/components/elements/Avatar.vue";
import FinishReviewModal from "@/components/elements/FinishReviewModal.vue";
import ClickToCopy from "@/components/elements/ClickToCopy.vue";
import RichTextArea from "@/components/elements/RichTextArea.vue";
import ThreeDotMenu from "@/components/elements/ThreeDotMenu.vue";
import PullRequestDebug from "@/components/elements/PullRequestDebug.vue";
import PullRequestCheck from "@/components/elements/PullRequestCheck.vue";
import PullRequestTimeline from "@/components/elements/PullRequestTimeline.vue";

import { Latch } from "../../../../shared/asyncUtils";
import { Github } from "../../../../shared/github";
import ReviewModule, {
  CommitData,
  ViewState
} from "../../store/modules/review";
import UIModule from "../../store/modules/ui";
import {
  PullRequestChange,
  fileMetadataTitle,
  chooseThreadArgs
} from "../../plugins/diff";
import {
  ReviewMetadata,
  CommentUser,
  ReviewStatus,
  ReviewHistory,
  Thread,
  Comment,
  ReviewPointer,
  LineLocator
} from "../../../../shared/types";
import * as typeUtils from "../../../../shared/typeUtils";
import AuthModule from "../../store/modules/auth";
import {
  KeyMap,
  KeyDescMap,
  combineHotkeys,
  PULL_REQUEST_KEY_DESC,
  PULL_REQUEST_KEY_MAP,
  CHANGE_ENTRY_KEY_DESC,
  COMMENT_THREAD_KEY_DESC
} from "../../plugins/hotkeys";

import { ChangeEntryAPI, PullRequestAPI } from "../api";
import { AddCommentEvent } from "../../plugins/events";
import {
  isBottomVisible,
  isTopVisible,
  makeTopVisible,
  makeWholeVisible,
  nextRender,
  nextRenderPromise
} from "../../plugins/dom";
import { config } from "../../plugins/config";
import { functions } from "../../plugins/firebase";
import { TraceMethod } from "../../plugins/perf";
import { loadRepoMove } from "../../plugins/data";
import { newRandomId } from "../../model/review";
import { getBaseName } from "@/plugins/binary";
import { getCommitTime } from "@/plugins/commits";
import { decodeLocator, encodeLocator } from "@/plugins/locator";
import { hashCode } from "@/plugins/hash";

@Component({
  components: {
    ChangeEntry,
    CommentThread,
    MarkdownContent,
    UserSearchModal,
    HotkeyModal,
    LabeledSelect,
    SimpleLabeledSelect,
    CommitSelectItem,
    TwoLineSelectItem,
    UserReviewIcon,
    ReviewLogItem,
    Avatar,
    FinishReviewModal,
    ClickToCopy,
    RichTextArea,
    ThreeDotMenu,
    PullRequestDebug,
    PullRequestCheck,
    PullRequestTimeline
  }
})
export default class PullRequest extends Vue implements PullRequestAPI {
  private authModule = getModule(AuthModule, this.$store);
  private reviewModule = getModule(ReviewModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);

  private github!: Github;

  public isReviewSending = false;
  public showFinishDialog = false;
  public showReviewersDialog = false;
  public showAssigneesDialog = false;
  public showCcsDialog = false;
  public loading = true;
  public expandSkippedChecks = false;
  public isDiffLoading = false;

  public isAnyChangeEntryExpanded = false;
  public isAnyChangeEntryCollapsed = true;

  public draftingDiscussionId: string | null = null;

  public meta: ReviewMetadata | null = null;

  public activeFileIndex = -1;
  public threadFilter = "all";

  public observer: IntersectionObserver | null = null;

  public editingDescription = false;
  public draftDescription = "";
  public draftDescriptionFooter = "";

  public editingTitle = false;
  public draftTitle = "";

  @TraceMethod("PullRequest#mounted")
  public async mounted(): Promise<void> {
    this.uiModule.beginLoading();

    this.github = new Github(
      AuthModule.getDelegate(this.authModule),
      config.github
    );

    const params = this.$route.params;
    const { owner, repo } = params;
    const number = Number.parseInt(params.number);

    const repoPromise = this.reviewModule.loadRepo({
      id: { owner, repo, number }
    });
    const userReposPromise = this.authModule.loadUserRepos();
    const latch = new Latch([repoPromise, userReposPromise]);
    try {
      await latch.wait();
    } catch (e) {
      const errors = (e as any).errors || [];
      console.warn("Error loading repo", errors);
    }

    // If the repo failed to load, check and see if there's a redirect we can use
    if (latch.getErrors().length > 0) {
      const move = await loadRepoMove({ owner, repo });
      if (move) {
        console.log("Redirecting due to", move);
        this.$router.push(`/pr/${move.to.owner}/${move.to.repo}/${number}`);
        return;
      }
    }

    // Check if the user has access (if the repo is private)
    if (this.reviewModule.repo.private) {
      const repoFullName = `${owner}/${repo}`;
      const hasAccess = this.authModule.userRepoNames.includes(repoFullName);

      if (!hasAccess) {
        this.$router.push("/settings?needsAccess=true");
        return;
      }
    }

    // Get view state from the URL (base, head, sort order, etc)
    const viewState = this.getStateFromRoute();

    try {
      const repoData = this.reviewModule.repo;
      const username = this.authModule.assertUser.username;
      await this.reviewModule.initializeReview({
        github: this.github,
        username,
        id: { owner, repo, number },
        viewState
      });

      // TODO: This should not be needed. We call loadRepo() above but then
      // the initializeReview() function calls stopListening() which clears
      // all data including the repo. For now we just set it back, but we
      // should have a cleaner way.
      this.reviewModule.setRepo(repoData);

      this.reviewModule.recalculateThreadLocations({ username });
    } catch (e) {
      console.warn(e);
    }

    if (!this.reviewModule.reviewLoaded) {
      // Otherwise show error and 404
      this.uiModule.addMessage({
        type: "error",
        text: "Something has gone wrong ... please report this."
      });

      console.warn(`Unable to load review ${owner}/${repo}/${number}`);
      this.$router.push("/404");
      return;
    }

    this.meta = Object.freeze(this.reviewModule.review.metadata);

    this.setFavicon();
    this.uiModule.endLoading();

    this.scrollToThreadFromHash();
    this.setViewWindowFromBaseAndHead(viewState.base, viewState.head);

    try {
      this.highlightLineFromHash();
    } catch (e) {
      // no-op
    }
  }

  get prChanges() {
    return Object.values(this.reviewModule.viewState.prChanges) || [];
  }

  get mode() {
    return this.reviewModule.mode;
  }

  get title() {
    if (this.reviewModule.reviewLoaded) {
      const title = this.reviewModule.review.metadata.title;
      const { owner, repo, number } = this.reviewModule.review.metadata;
      return `${title} - ${owner}/${repo} #${number}`;
    }

    return "CodeApprove";
  }

  @Watch("localStatus")
  public setFavicon() {
    if (!this.reviewModule.reviewLoaded) {
      return;
    }

    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      (link as any).href = typeUtils.statusFavicon(this.localStatus);
    }
  }

  @Watch("loaded")
  public onLoaded() {
    if (!this.loaded) {
      return;
    }

    nextRender(() => {
      this.setupHeader();
    });
  }

  private setupHeader() {
    const prheader = document.querySelector("#prheader");
    const intersector = document.querySelector("#prheader-intersector");
    if (!prheader || !intersector) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entries, observer) => {
        const entry = entries[0];
        if (!entry.isIntersecting) {
          prheader.classList.add("stuck");
        } else {
          prheader.classList.remove("stuck");
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      }
    );
    this.observer.observe(intersector);
  }

  get dirty() {
    return this.reviewModule.viewState.dirty;
  }

  get commitStatus() {
    return this.reviewModule.commitStatus;
  }

  public getStateFromRoute(): Partial<ViewState> {
    const res: Partial<ViewState> = {};
    const sortOrder = this.getQueryParam("sort");
    if (sortOrder) {
      res.sortOrder = sortOrder;
    }

    const base = this.getQueryParam("base");
    if (base) {
      res.base = base;
    }

    const head = this.getQueryParam("head");
    if (head) {
      res.head = head;
    }

    return res;
  }

  private setQueryParam(key: string, val: string | undefined) {
    if (this.$route.query[key] === val) {
      return;
    }

    const query = { ...this.$route.query };
    if (val) {
      query[key] = val;
    } else {
      delete query[key];
    }

    this.$router.replace({ query });
  }

  private getQueryParam(key: string) {
    const val = this.$route.query[key];
    if (typeof val === "string") {
      return val;
    }

    return undefined;
  }

  get isDebug() {
    return !!this.getQueryParam("debug");
  }

  @Watch("dirty")
  private watchDirty(dirty: boolean) {
    if (dirty) {
      this.uiModule.addMessage({
        type: "error",
        text:
          "There have been changes to this Pull Request. Click here to refresh.",
        action: events.REQUEST_REFRESH_EVENT
      });
    }
  }

  @Watch('$route', { deep: true })
  public onRouteChange(newVal: any, oldVal: any) {
    const newHash = newVal?.hash;
    const oldHash = oldVal?.hash;

    if (!!newHash && oldHash !== newHash) {
      this.highlightLineFromHash()
    }
  }

  public scrollToThreadFromHash() {
    const hash = window.location.hash;
    if (!hash) {
      return;
    }

    if (!hash.startsWith('#thread-')) {
      return
    }

    nextRender(() => {
      const el = document.querySelector(hash);
      if (el) {
        makeWholeVisible(el, 200, 100);
      }
    });
  }

  public highlightLineFromHash() {
    const hash = window.location.hash;
    if (!hash || !hash?.startsWith('#line-')) {
      return
    }

    const encoded = hash.split('#line-')[1];
    if (!encoded) {
      return
    }

    const decoded = decodeLocator(encoded);
    if (!decoded) {
      return
    }

    const { base, head } = this.reviewModule.viewState;
    const side = base.startsWith(decoded.c)
      ? 'left'
      : head.startsWith(decoded.c)
      ? 'right'
      : undefined;
    if (!side) {
      return
    }

    const changeIndex = this.sortedChanges.findIndex(c => {
      const path = side === 'left' ? c.metadata.from : c.metadata.to;
      return hashCode(path) === decoded.f;
    })

    if (changeIndex < 0) {
      return
    }

    const line = decoded.l;
    console.log('highlightLineFromHash', { changeIndex, side, line });

    const changingFiles = this.activeFileIndex !== changeIndex;
    nextRenderPromise()
      .then(() => {
        if (changingFiles) {
          this.setActiveChangeEntry(changeIndex);
        }
        this.scrollToActive({ minimalScroll: true });
        return nextRenderPromise()
      })
      .then(() => {
        this.getCurrentChangeEntry()?.expand()
        return nextRenderPromise()
      })
      .then(() => {
        const disableScroll = !changingFiles;
        this.getCurrentChangeEntry()?.setActiveFileLine(line, side, { disableScroll })
      })
      .catch((e) => {
        // No-op
      });
  }

  public threadIsLinked(thread: Thread) {
    return window.location.hash.endsWith(thread.id);
  }

  @Watch("threads")
  public onThreadsChanged() {
    // When the threads list is updated it could mean that our draft discussion
    // has been sent, meaning that we should hide the new discussion box and let it
    // be shown by the threads list
    if (this.draftingDiscussionId !== null) {
      const d = this.threads.find(t => t.id === this.draftingDiscussionId);
      if (d) {
        this.endDraftingDiscussion();
      }
    }
  }

  get isDraftingDiscussion() {
    if (this.draftingDiscussionId === null) {
      return false;
    }

    const d = this.threads.find(t => t.id === this.draftingDiscussionId);
    if (d) {
      return false;
    }

    return true;
  }

  public startDraftingDiscussion() {
    this.draftingDiscussionId = newRandomId();
  }

  public endDraftingDiscussion() {
    this.draftingDiscussionId = null;
  }

  public onCommentEvent(e: Partial<AddCommentEvent>) {
    console.log(`PullRequest#onCommentEvent: ${JSON.stringify(e)}`);

    if (e.type === "reply_add_comment") {
      if (!e.threadId) {
        console.error(`Reply event had no thread id: ${JSON.stringify(e)}`);
        return;
      }

      const thread = this.reviewModule.threadById(e.threadId);
      if (!thread) {
        console.error(
          `Attempting to comment on thread ${
            e.threadId
          } which does not exist: ${JSON.stringify(e)}`
        );
        return;
      }
    }

    // TODO(polish): Should add some runtime checking to see
    //               if all the fields are really here.
    const finalEvent = e as AddCommentEvent;
    const user: CommentUser = {
      username: this.authModule.assertUser.username,
      photoURL: this.authModule.assertUser.photoURL
    };

    this.reviewModule.handleAddCommentEvent({ e: finalEvent, user });
  }

  public onLineClickEvent(e: ChangeEntryLineClickEvent) {
    const commit = e.side === 'right'
      ? this.reviewModule.viewState.head
      : this.reviewModule.viewState.base;

    const locator: LineLocator = {
      commit,
      file: e.file,
      line: e.line,
    }

    const encoded = encodeLocator(locator);
    const hash = `line-${encoded}`
    window.location.hash = hash;
  }

  public async discardDrafts() {
    const res = confirm("Are you sure you want to discard all draft comments?");
    if (!res) {
      return;
    }

    this.showFinishDialog = false;
    await this.reviewModule.discardDraftComments({
      username: this.authModule.assertUser.username
    });
  }

  public async finishReview(evt: events.FinishReviewEvent) {
    console.log(`finishReview(${evt.approved})`);

    this.uiModule.beginLoading();
    this.isReviewSending = true;
    this.showFinishDialog = false;

    // TODO(polish): Move this logc into the sendReview() function so
    //               that sending with whole-review comment is atomic.
    //               Then we can also get rid of 'isReviewSending'

    // Add the whole-review comment as a draft
    if (evt.comment) {
      const e = events.getNewDiscussionEvent({
        content: evt.comment.text,
        resolve: evt.comment.resolved,
        reviewSummary: true
      });

      const user: CommentUser = {
        username: this.authModule.assertUser.username,
        photoURL: this.authModule.assertUser.photoURL
      };

      await this.reviewModule.handleAddCommentEvent({ e, user });
    }

    // TODO(polish): This should not be necessary but I think sometimes the comment
    // addition is not happening fast enough
    await new Promise(res => setTimeout(res, 500));

    const login = this.authModule.assertUser.username;
    const approved = evt.approved;
    await this.reviewModule.sendReview({ login, approved });

    this.uiModule.endLoading();
    this.isReviewSending = false;
  }

  public onReviewerSelected(event: { login: string }) {
    this.showReviewersDialog = false;
    this.reviewModule.addReviewer({
      login: event.login,
      approved: false
    });
  }

  public onAssigneeSelected(event: { login: string }) {
    this.showAssigneesDialog = false;
    this.addAssignee(event.login);
  }

  public onCcSelected(event: { login: string }) {
    this.showCcsDialog = false;
    this.reviewModule.addCc({
      login: event.login
    });
  }

  public onChangeEntryExpand() {
    const changes = this.getUnsortedChangeEntries();
    if (changes.length === 0) {
      this.isAnyChangeEntryExpanded = false;
      this.isAnyChangeEntryCollapsed = true;
    } else {
      this.isAnyChangeEntryExpanded = changes.some(ce => ce.isExpanded());
      this.isAnyChangeEntryCollapsed = changes.some(ce => !ce.isExpanded());
    }
  }

  public collapseAll() {
    const changes = this.getSortedChangeEntries();
    for (const c of changes) {
      c.deactivate();
      c.collapse();
    }
    this.activeFileIndex = -1;
    this.scrollToActive();
  }

  public expandAll() {
    const changes = this.getSortedChangeEntries();
    for (const c of changes) {
      c.expand();
    }
  }

  public async viewAllCommits() {
    this.reviewModule.setViewWindow({ viewSinceTime: 0, viewUntilTime: 0 });

    const head = this.assertMeta.head.sha;
    const base = this.assertMeta.base.sha;

    await this.reloadDiff(base, head);
  }

  get viewSinceKey() {
    return `${this.reviewModule.viewState.viewSinceTime}`;
  }

  get viewSinceOptions() {
    const reviews = this.histories.filter(h => {
      const hasComments = h.actions.some(a => a.type === "add_comment");
      const hasChangedApproval = h.approval !== h.previousApproval;

      return hasComments || hasChangedApproval;
    });

    const myLastReview = reviews.find(
      h => h.username === this.authModule.assertUser.username
    );

    const options = [
      {
        key: "0",
        value: {
          message: "All changes",
          submessage: "all",
          timestamp: 0
        }
      }
    ];

    if (myLastReview) {
      options.push({
        key: `${myLastReview.timestamp}`,
        value: {
          message: "Since my last review",
          submessage: typeUtils.formatTimestampLong(myLastReview.timestamp),
          timestamp: myLastReview.timestamp
        }
      });
    }

    for (const h of reviews) {
      if (h === myLastReview) {
        continue;
      }

      options.push({
        key: `${h.timestamp}`,
        value: {
          message: `Since review by ${h.username}`,
          submessage: typeUtils.formatTimestampLong(h.timestamp),
          timestamp: h.timestamp
        }
      });
    }
    return options;
  }

  public onTimelineChange(e: { start: number; end?: number }) {
    if (e.start === 0 && (e.end === 0 || e.end === undefined)) {
      this.viewAllCommits();
      return;
    }

    this.setViewWindow(e.start, e.end ?? 0);
  }

  public onViewSinceSelected(key: string) {
    console.log("onViewSinceSelected", key);
    if (key === this.viewSinceKey) {
      return;
    }

    if (key === "0") {
      this.viewAllCommits();
      return;
    }

    const option = this.viewSinceOptions.find(o => o.key === key);
    if (!option) {
      console.warn(`Could not find viewSince key ${key}`);
      return;
    }

    const viewSinceTime = option.value.timestamp;
    this.setViewWindow(viewSinceTime, 0);
  }

  private setViewWindow(viewSinceTime: number, viewUntilTime: number) {
    // Set local state
    this.reviewModule.setViewWindow({ viewSinceTime, viewUntilTime });

    // Synchronize the two LabeledSelects
    this.threadFilter = "all";

    const datedCommits = this.commits.map(c => {
      const time = getCommitTime(c);
      return {
        c,
        time
      };
    });

    // The last commit BEFORE the cutoff is the base, since we want changes
    // since then.
    const lastBefore = typeUtils.findLastIndex(datedCommits, e => {
      return !!e.time && e.time < this.reviewModule.viewState.viewSinceTime;
    });

    const lastIncluded = typeUtils.findLastIndex(datedCommits, e => {
      return !!e.time && e.time <= this.reviewModule.viewState.viewUntilTime;
    });

    const startIndex = viewSinceTime > 0
      ? Math.max(lastBefore, 0)
      : 0;
    const endIndex = viewUntilTime > 0
      ? lastIncluded
      : this.commits.length - 1;

    const base = this.commits[startIndex].sha;
    const head = this.commits[endIndex].sha;

    this.reloadDiff(base, head);
  }

  public onSortOrderSelected(sortOrder: string) {
    this.reviewModule.setSortOrder({ sortOrder });
    if (sortOrder === "diff-desc") {
      this.setQueryParam("sort", undefined);
    } else {
      this.setQueryParam("sort", sortOrder);
    }
  }

  public async onBaseSelected(base: string) {
    this.setViewWindowFromBaseAndHead(base, undefined);
    this.reloadDiff(base, undefined);
  }

  public async onHeadSelected(head: string) {
    this.setViewWindowFromBaseAndHead(undefined, head);
    this.reloadDiff(undefined, head);
  }

  public async onBaseAndHeadSelected(base: string, head: string) {
    this.setViewWindowFromBaseAndHead(base, head);
    this.reloadDiff(base, head);
  }

  private setViewWindowFromBaseAndHead(
    base: string | undefined,
    head: string | undefined
  ) {
    const baseCommit = this.commits.find(c => c.sha === base);
    const headCommit = this.commits.find(c => c.sha === head);

    let viewSinceTime = 0;
    if (base && base !== this.assertMeta.base.sha && baseCommit) {
      const time = getCommitTime(baseCommit) ?? 0;
      viewSinceTime = time;
    }

    let viewUntilTime = 0;
    if (head && head !== this.assertMeta.head.sha && headCommit) {
      const time = getCommitTime(headCommit) ?? 0;
      viewUntilTime = time;
    }

    this.reviewModule.setViewWindow({ viewSinceTime, viewUntilTime });
  }

  public async reloadDiff(
    baseSha: string | undefined,
    headSha: string | undefined
  ) {
    const base = baseSha ?? this.assertMeta.base.sha;
    const head = headSha ?? this.assertMeta.head.sha;
    console.log(`reloadDiff(${base}, ${head})`);

    if (base === this.assertMeta.base.sha) {
      this.setQueryParam("base", undefined);
    } else {
      this.setQueryParam("base", base);
    }

    if (head === this.assertMeta.head.sha) {
      this.setQueryParam("head", undefined);
    } else {
      this.setQueryParam("head", head);
    }

    this.uiModule.beginLoading();
    this.isDiffLoading = true;

    this.collapseAll();

    try {
      await this.reviewModule.loadDiffs({
        github: this.github,
        base,
        head,
        username: this.authModule.assertUser.username
      });
    } catch (e) {
      console.error(e);
      this.uiModule.addMessage({
        type: "error",
        text:
          "Something went wrong, CodeApprove was unable to load the diff. Please report this."
      });
    }

    // Re-calculate the expanded state, then scroll back to the top
    this.onChangeEntryExpand();
    this.scrollToActive();

    this.isDiffLoading = false;
    this.uiModule.endLoading();
  }

  public displayCommit(commit: CommitData | null): string {
    if (!commit) {
      return "";
    }

    let shortMsg = commit.commit.message;
    if (shortMsg.length >= 25) {
      shortMsg = shortMsg.substring(0, 22) + "...";
    }

    return `${commit.sha.substring(0, 7)} ${shortMsg}`;
  }

  public changeEntryKey(change: PullRequestChange) {
    const { base, head } = this.reviewModule.viewState;
    return `${change.metadata.from}@${base}-${change.metadata.to}@${head}`;
  }

  public setActiveChangeEntry(index: number) {
    if (index === this.activeFileIndex) {
      console.log(`PR#setActiveChangeEntry(${index}): no change needed.`);
      return;
    }

    const curr = this.getCurrentChangeEntry();
    if (curr) {
      curr.deactivate();
    }

    this.activeFileIndex = index;
    const next = this.getCurrentChangeEntry();
    if (next) {
      next.activate();
    } else {
      console.warn(`setActiveChangeEntry: could not activate ${index}`);
    }
  }

  public scrollToActive(options?: { minimalScroll: boolean }) {
    const sorted = this.getSortedChangeEntries();
    if (sorted.length <= 0) {
      return;
    }

    const currentChange = this.getCurrentChangeEntry();
    const firstChange = sorted[0];

    const el = currentChange ? currentChange.$el : firstChange.$el;

    // In "minimalScroll" mode, we are fine if the element is visible at all
    if (options?.minimalScroll && (isTopVisible(el) || isBottomVisible(el))) {
      return;
    }

    makeTopVisible(el, 300);
  }

  public onNextFileOrLine(e: Event) {
    const ce = this.getCurrentChangeEntry();
    if (ce && ce.isExpanded()) {
      ce.nextLine(e);
    } else {
      this.onNextFile(e);
    }
  }

  public onNextFile(e: Event) {
    const maxIndex = this.reviewModule.viewState.diffs.length - 1;
    if (this.activeFileIndex === maxIndex) {
      return;
    }

    this.setActiveChangeEntry(Math.min(this.activeFileIndex + 1, maxIndex));
    this.scrollToActive();
    e.preventDefault();
  }

  public onPrevFileOrLine(e: Event) {
    const ce = this.getCurrentChangeEntry();
    if (ce && ce.isExpanded()) {
      ce.prevLine(e);
    } else {
      this.onPrevFile(e);
    }
  }

  public onPrevFile(e: Event) {
    if (this.activeFileIndex === 0) {
      return;
    }

    this.setActiveChangeEntry(Math.max(this.activeFileIndex - 1, 0));
    this.scrollToActive();
    e.preventDefault();
  }

  public onToggleFile(expand?: boolean) {
    const ce = this.getCurrentChangeEntry()!;

    if (ce) {
      if (expand === true) {
        ce.expand();
      } else if (expand === false) {
        ce.collapse();
      } else {
        ce.toggle();
      }

      ce.activate();
      makeTopVisible(ce.$el, 200);
    }
  }

  public onToggleFileReviewed() {
    const ce = this.getCurrentChangeEntry();
    if (ce) {
      const before = ce.isReviewed();
      ce.toggleReviewed();
      if (!before) {
        ce.collapse();
      }
    }
  }

  public goToThread(thread: Thread) {
    const base = this.reviewModule.viewState.base;
    const head = this.reviewModule.viewState.head;
    const args = chooseThreadArgs(thread, base, head);

    console.log("goToThread", args.sha, args.file, args.line);

    const changes = this.prChanges;
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];

      const match =
        (args.sha === this.reviewModule.viewState.base &&
          change.file.from === args.file) ||
        (args.sha === this.reviewModule.viewState.head &&
          change.file.to === args.file);

      if (match) {
        console.log(`goToThread: jumping to ChangeEntry index ${i}`);
        this.setActiveChangeEntry(i);
        this.getCurrentChangeEntry()!.expand();
        this.scrollToActive();

        // Give the ChangeEntry a beat to load if it's being expanded.
        nextRender(() => {
          // Whole-file comments have negative line, just go to the top
          const lineNum = Math.max(args.line, 0);
          this.getCurrentChangeEntry()!.setActiveFileLine(lineNum, args.side);
        });

        return;
      }
    }
  }

  get commitStatusDisplay() {
    const mergeable = this.commitStatus.merge.mergeable;
    const inProgress = this.commitStatus.checks.some(c => !c.completed);
    const hasCheckFailures = this.commitStatus.checks.some(c => c.failed);
    const success = mergeable && !hasCheckFailures;

    const status = inProgress
      ? ReviewStatus.NEEDS_REVIEW
      : success
      ? ReviewStatus.APPROVED
      : ReviewStatus.CLOSED_UNMERGED;

    const classes = typeUtils.statusClass(status);
    const icon = typeUtils.statusIconName(status);

    return {
      text: classes.text,
      border: classes.border,
      icon
    };
  }

  get skippedChecks() {
    return this.commitStatus.checks.filter(c => c.completed && c.skipped);
  }

  get completedChecks() {
    return this.commitStatus.checks.filter(c => c.completed && !c.skipped);
  }

  public getPointerDisplay(pointer: ReviewPointer) {
    if (pointer.user.login === this.assertMeta.owner) {
      return pointer.ref;
    }

    return pointer.label;
  }

  public async syncPullRequest() {
    this.uiModule.beginLoading();

    try {
      const fn = httpsCallable(functions(), "api/syncPullRequest");
      await fn({
        owner: this.assertMeta.owner,
        repo: this.assertMeta.repo,
        number: this.assertMeta.number
      });
    } catch (e) {
      console.warn("Failed to sync pr", e);
    }

    this.uiModule.endLoading();
  }

  private getCurrentChangeEntry(): ChangeEntryAPI | undefined {
    if (this.activeFileIndex < 0) {
      return;
    }

    return this.getSortedChangeEntries()[this.activeFileIndex];
  }

  private getUnsortedChangeEntries(): ChangeEntryAPI[] {
    const changes = this.$refs.changes as ChangeEntryAPI[];
    if (!changes) {
      return [];
    }
    return changes;
  }

  private getSortedChangeEntries(): ChangeEntryAPI[] {
    const changes = this.getUnsortedChangeEntries();
    return changes.sort((a, b) => {
      return a.getIndex() - b.getIndex();
    });
  }

  get sortedChanges(): PullRequestChange[] {
    const changes = this.prChanges || [];
    if (this.sortOrder === "name") {
      return changes.sort((a, b) => {
        const aTitle = fileMetadataTitle(a.metadata);
        const bTitle = fileMetadataTitle(b.metadata);

        const aBaseName = getBaseName(aTitle);
        const bBaseName = getBaseName(bTitle);

        // TODO: What about file systems that use \ for paths?
        const aSegments = aBaseName.split("/");
        const bSegments = bBaseName.split("/");

        const aFolders = aSegments.slice(0, aSegments.length - 1);
        const bFolders = bSegments.slice(0, bSegments.length - 1);

        const aDir = aFolders.join("/");
        const bDir = bFolders.join("/");

        // Rank /a/z.foo above /a/b/c.foo
        if (aDir !== bDir) {
          if (bDir.startsWith(aDir)) {
            return -1;
          }

          if (aDir.startsWith(bDir)) {
            return 1;
          }
        }

        // Compare path segments one by one
        const toCompare = Math.min(aSegments.length, bSegments.length);
        for (let i = 0; i < toCompare; i++) {
          if (aSegments[i] === bSegments[i]) {
            continue;
          }

          return aSegments[i].localeCompare(bSegments[i]);
        }

        return aTitle.localeCompare(bTitle);
      });
    }

    if (this.sortOrder === "diff-asc" || this.sortOrder === "diff-desc") {
      return changes.sort((a, b) => {
        const totalA = a.metadata.additions + a.metadata.deletions;
        const totalB = b.metadata.additions + b.metadata.deletions;
        return this.sortOrder === "diff-asc"
          ? totalA - totalB
          : totalB - totalA;
      });
    }

    return changes;
  }

  get liveMeta(): ReviewMetadata {
    return this.reviewModule.review.metadata;
  }

  get assertMeta(): ReviewMetadata {
    if (this.meta === null) {
      throw new Error("Assertion error: meta is null");
    }

    return this.meta;
  }

  get hasViewWindow() {
    return this.reviewModule.hasViewWindow;
  }

  get viewSinceTime() {
    return this.reviewModule.viewState.viewSinceTime;
  }

  get viewUntilTime() {
    return this.reviewModule.viewState.viewUntilTime;
  }

  get commits() {
    return this.reviewModule.viewState.commits;
  }

  get base() {
    return this.reviewModule.viewState.base;
  }

  get head() {
    return this.reviewModule.viewState.head;
  }

  get visibleCommitShas() {
    return this.reviewModule.viewState.visibleCommits.map(c => c.sha);
  }

  get eligibleHeadCommits() {
    const baseIndex = this.commits.findIndex(c => c.sha === this.base);
    if (baseIndex < 0) {
      return this.commits.map(c => c.sha);
    }

    return this.commits.slice(baseIndex + 1).map(c => c.sha);
  }

  get eligibleBaseCommits() {
    const headIndex = this.commits.findIndex(c => c.sha === this.head);
    if (headIndex < 0) {
      return this.commits.map(c => c.sha);
    }

    return this.commits.slice(0, headIndex).map(c => c.sha);
  }

  get description(): string {
    return this.reviewModule.review.metadata.body || "";
  }

  get renderableDescription(): string {
    return this.reviewModule.renderableBody || "";
  }

  get descriptionOrDefault(): string {
    const desc = this.renderableDescription;

    // Check if the description (minus the CA link) is empty
    const { description } = this.splitDescription(desc);
    if (!description || description.trim() === "") {
      return "_No description provided_";
    }

    return desc;
  }

  get threads(): Thread[] {
    const username = this.authModule.assertUser.username;
    let threads = this.reviewModule.visibleThreads;

    // Filter by resolved or unresolved status
    // TODO(stop): This should use "will be resolved" status!
    if (
      this.threadFilter === "unresolved" ||
      this.threadFilter === "resolved"
    ) {
      const resolvedFilter = this.threadFilter === "resolved";
      threads = threads.filter(t => t.resolved === resolvedFilter);
    }

    // Cache comments by thread
    const threadComments: Record<string, Comment[]> = {};
    for (const t of threads) {
      threadComments[t.id] = this.reviewModule.visibleCommentsByThread(
        t.id,
        username
      );
    }

    // Only show threads with visible comments
    threads = threads.filter(t => threadComments[t.id].length > 0);

    // Order threads by their first comment
    const byFirstComment = (a: Thread, b: Thread) => {
      const aComments = threadComments[a.id];
      const bComments = threadComments[b.id];

      if (aComments.length === 0) {
        console.warn(`Thread has no comments: ${a.id}`);
        return 1;
      }

      if (bComments.length === 0) {
        console.warn(`Thread has no comments: ${b.id}`);
        return -1;
      }

      return bComments[0].timestamp - aComments[0].timestamp;
    };

    const sortedThreads = threads.sort(byFirstComment);

    // Order: drafts, then unresolved, then mostly resolved, then fully resolved
    const [drafts, nonDrafts] = typeUtils.splitFilter(
      sortedThreads,
      t => t.draft
    );
    const [resolved, unresolved] = typeUtils.splitFilter(
      nonDrafts,
      t => t.resolved
    );
    const [fullyResolved, mostlyResolved] = typeUtils.splitFilter(resolved, t =>
      typeUtils.isFullyResolved(t, threadComments[t.id])
    );

    return [...drafts, ...unresolved, ...mostlyResolved, ...fullyResolved];
  }

  get histories(): ReviewHistory[] {
    return this.reviewModule.histories.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  }

  get hotKeyDescriptions(): KeyDescMap {
    return combineHotkeys(
      PULL_REQUEST_KEY_DESC,
      CHANGE_ENTRY_KEY_DESC,
      COMMENT_THREAD_KEY_DESC
    );
  }

  get hotKeyMap(): KeyMap {
    return PULL_REQUEST_KEY_MAP(this);
  }

  get userHasApproved() {
    return this.reviewModule.review.state.approvers.includes(
      this.authModule.assertUser.username
    );
  }

  get isClosed() {
    return this.reviewModule.review.state.closed;
  }

  get localStatus() {
    return this.reviewModule.estimatedStatus;
  }

  get isApproved() {
    return this.localStatus === ReviewStatus.APPROVED;
  }

  get hasUnresolved() {
    return this.numUnresolvedThreads > 0;
  }

  get statusIconName() {
    return typeUtils.statusIconName(this.localStatus);
  }

  get statusClass() {
    return typeUtils.statusClass(this.localStatus);
  }

  get statusText() {
    return typeUtils.statusText(this.localStatus);
  }

  get reviewers(): string[] {
    return this.reviewModule.review.state.reviewers;
  }

  get assignees(): string[] {
    return this.reviewModule.review.state.assignees;
  }

  get ccs(): string[] {
    return this.reviewModule.review.state.ccs;
  }

  get userIsAuthor(): boolean {
    return this.authModule.assertUser.username === this.assertMeta.author;
  }

  get sortOrder(): string {
    return this.reviewModule.viewState.sortOrder;
  }

  public didApprove(login: string): boolean {
    return this.reviewModule.review.state.approvers.includes(login);
  }

  public canRemoveAssignee(username: string) {
    // If the author is the only assignee, they cannot be removed because
    // a review must always be assigned to someone.
    if (this.assignees.length === 1 && username == this.assertMeta.author) {
      return false;
    }

    return (
      this.canModifyMetadata() ||
      this.authModule.assertUser.username === username
    );
  }

  public canModifyMetadata(): boolean {
    return this.reviewModule.viewState.userCanWrite || this.userIsAuthor;
  }

  public canRemoveReviewer(login: string): boolean {
    const myLogin = this.authModule.assertUser.username;

    // Repo writes can do this
    if (this.reviewModule.viewState.userCanWrite) {
      return true;
    }

    // Users can remove themselves
    if (login === myLogin) {
      return true;
    }

    // The PR author can remove reviewers
    if (this.userIsAuthor) {
      return true;
    }

    return false;
  }

  public removeReviewer(login: string) {
    this.reviewModule.removeReviewer({ login });
  }

  public removeAssignee(login: string) {
    this.reviewModule.removeAssignee({ login });
  }

  public addAssignee(login: string) {
    this.reviewModule.addAssignee({
      login
    });
  }

  public removeCc(login: string) {
    this.reviewModule.removeCc({ login });
  }

  public splitDescription(val: string) {
    // If the description contains the CodeApprove icon, we hide it here and add it back
    const tagRe = /[\n]?<a.+?data-ca-tag.+<\/a>/g;
    const tagMatch = val.match(tagRe);

    if (!tagMatch) {
      return { description: val, footer: "" };
    }

    const footer = tagMatch[0];
    const description = val.replace(tagMatch[0], "");
    return { description, footer };
  }

  public beginEditingDescription() {
    const { description, footer } = this.splitDescription(this.description);

    this.draftDescription = description;
    this.draftDescriptionFooter = footer;

    this.editingDescription = true;
  }

  public async finishEditingDescription() {
    this.uiModule.beginLoading();

    const newDescription = this.draftDescription + this.draftDescriptionFooter;

    const { owner, repo, number } = this.assertMeta;
    await this.github.updatePullRequestBody(
      owner,
      repo,
      number,
      newDescription
    );

    // Update local state so we don't have to wait for the webhook
    const newMetadata: ReviewMetadata = {
      ...this.reviewModule.review.metadata,
      body: newDescription
    };
    this.reviewModule.setReviewMetadata(newMetadata);

    this.editingDescription = false;
    this.uiModule.endLoading();
  }

  public beginEditingTitle() {
    this.draftTitle = this.assertMeta.title;
    this.editingTitle = true;
  }

  public async finishEditingTitle() {
    if (this.draftTitle === this.assertMeta.title) {
      this.editingTitle = false;
      return;
    }

    this.uiModule.beginLoading();

    const { owner, repo, number } = this.assertMeta;
    await this.github.updatePullRequestTitle(
      owner,
      repo,
      number,
      this.draftTitle
    );

    // Update local state so we don't have to wait for the webhook
    const newMetadata: ReviewMetadata = {
      ...this.reviewModule.review.metadata,
      title: this.draftTitle
    };
    this.reviewModule.setReviewMetadata(newMetadata);

    this.editingTitle = false;
    this.uiModule.endLoading();
  }

  get githubUrl() {
    if (!this.meta) {
      return "";
    }
    return `https://github.com/${this.assertMeta.owner}/${this.assertMeta.repo}/pull/${this.assertMeta.number}`;
  }

  get loaded() {
    return !!this.authModule.user && !!this.meta;
  }

  get drafts() {
    // Show only MY drafts
    return this.reviewModule.drafts.filter(
      t => t.username === this.authModule.assertUser.username
    );
  }

  get numThreads() {
    return this.reviewModule.threads.filter(t => !t.draft).length;
  }

  get numUnresolvedThreads() {
    return this.reviewModule.threads.filter(x => !x.draft && !x.resolved)
      .length;
  }

  get numUnresolvedThreadsAfter() {
    const { delta } = this.reviewModule.countResolutions;
    return this.reviewModule.review.state.unresolved + delta;
  }

  get numTotalCommits() {
    return this.reviewModule.viewState.commits.length;
  }

  get numVisibleCommits() {
    return this.reviewModule.viewState.visibleCommits.length;
  }

  get participants() {
    return (excludeSelf: boolean) => {
      // Gather all reviewers, cc'ed, assignees, author
      const state = this.reviewModule.review.state;
      const all = [
        this.assertMeta.author,
        ...state.assignees,
        ...state.reviewers,
        ...state.ccs
      ];

      // Deduplicate and sort
      const set = new Set<string>(all);
      if (excludeSelf) {
        set.delete(this.assertMeta.author);
      }
      return Array.from(set).sort();
    };
  }

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
    if (this.observer !== null) {
      this.observer.disconnect();
    }
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    console.log("PullRequest#beforeRouteLeave()");

    if (this.drafts.length) {
      if (
        !window.confirm(
          "You have unsent drafts. Leave without sending your review?"
        )
      ) {
        return;
      }
    }
    this.reviewModule.stopListening();
    next();
  }

  private preventNav(e: BeforeUnloadEvent) {
    // TODO(polish): This should actually check if there are comment boxes with
    // text not yet saved as a draft, rather than drafts (which are saved).
    //
    // if (this.drafts.length > 0) {
    //   e.preventDefault();
    //   e.returnValue = "";
    // }
  }
}
