import { DiffMode } from "../../../shared/types";

export type SettingsKey<T> = string;

export class LocalSettings {
  static KEY_DIFF_MODE: SettingsKey<DiffMode> = "localsettings:key_diff_mode";

  static KEY_SORT_ORDER: SettingsKey<string> = "localsettings:key_sort_order";

  static KEY_DIFF_SERVER: SettingsKey<string> = "localsettings:key_diff_server";

  static KEY_THEME: SettingsKey<string> = "localsettings:key_theme";

  static set<T>(key: SettingsKey<T>, value: T) {
    const val = typeof value === "string" ? value : "";
    localStorage.setItem(key, val);
  }

  static getOrDefault<T>(key: SettingsKey<T>, def: T): T {
    const val = localStorage.getItem(key);
    if (val) {
      return (val as unknown) as T;
    }

    return def;
  }
}
