


























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThreeDotMenu extends Vue {
  @Prop() options!: string[];

  public expanded = false;

  public emitOption(option: string) {
    const asEvent = option
      .toLowerCase()
      .split(" ")
      .join("-");

    this.$emit(asEvent);
  }
}
