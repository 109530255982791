/**
 * List from:
 * https://github.com/sindresorhus/binary-extensions
 */
const BINARY_EXTENSIONS = [
  "3dm",
  "3ds",
  "3g2",
  "3gp",
  "7z",
  "a",
  "aac",
  "adp",
  "ai",
  "aif",
  "aiff",
  "alz",
  "ape",
  "apk",
  "appimage",
  "ar",
  "arj",
  "asf",
  "au",
  "avi",
  "bak",
  "baml",
  "bh",
  "bin",
  "bk",
  "bmp",
  "btif",
  "bz2",
  "bzip2",
  "cab",
  "caf",
  "cgm",
  "class",
  "cmx",
  "cpio",
  "cr2",
  "cur",
  "dat",
  "dcm",
  "deb",
  "dex",
  "djvu",
  "dll",
  "dmg",
  "dng",
  "doc",
  "docm",
  "docx",
  "dot",
  "dotm",
  "dra",
  "DS_Store",
  "dsk",
  "dts",
  "dtshd",
  "dvb",
  "dwg",
  "dxf",
  "ecelp4800",
  "ecelp7470",
  "ecelp9600",
  "egg",
  "eol",
  "eot",
  "epub",
  "exe",
  "f4v",
  "fbs",
  "fh",
  "fla",
  "flac",
  "flatpak",
  "fli",
  "flv",
  "fpx",
  "fst",
  "fvt",
  "g3",
  "gh",
  "gif",
  "graffle",
  "gz",
  "gzip",
  "h261",
  "h263",
  "h264",
  "icns",
  "ico",
  "ief",
  "img",
  "ipa",
  "iso",
  "jar",
  "jpeg",
  "jpg",
  "jpgv",
  "jpm",
  "jxr",
  "key",
  "ktx",
  "lha",
  "lib",
  "lvp",
  "lz",
  "lzh",
  "lzma",
  "lzo",
  "m3u",
  "m4a",
  "m4v",
  "mar",
  "mdi",
  "mht",
  "mid",
  "midi",
  "mj2",
  "mka",
  "mkv",
  "mmr",
  "mng",
  "mobi",
  "mov",
  "movie",
  "mp3",
  "mp4",
  "mp4a",
  "mpeg",
  "mpg",
  "mpga",
  "mxu",
  "nef",
  "npx",
  "numbers",
  "nupkg",
  "o",
  "oga",
  "ogg",
  "ogv",
  "otf",
  "pages",
  "pbm",
  "pcx",
  "pdb",
  "pdf",
  "pea",
  "pgm",
  "pic",
  "png",
  "pnm",
  "pot",
  "potm",
  "potx",
  "ppa",
  "ppam",
  "ppm",
  "pps",
  "ppsm",
  "ppsx",
  "ppt",
  "pptm",
  "pptx",
  "psd",
  "pya",
  "pyc",
  "pyo",
  "pyv",
  "qt",
  "rar",
  "ras",
  "raw",
  "resources",
  "rgb",
  "rip",
  "rlc",
  "rmf",
  "rmvb",
  "rpm",
  "rtf",
  "rz",
  "s3m",
  "s7z",
  "scpt",
  "sgi",
  "shar",
  "snap",
  "sil",
  "sketch",
  "slk",
  "smv",
  "snk",
  "so",
  "stl",
  "suo",
  "sub",
  "swf",
  "tar",
  "tbz",
  "tbz2",
  "tga",
  "tgz",
  "thmx",
  "tif",
  "tiff",
  "tlz",
  "ttc",
  "ttf",
  "txz",
  "udf",
  "uvh",
  "uvi",
  "uvm",
  "uvp",
  "uvs",
  "uvu",
  "viv",
  "vob",
  "war",
  "wav",
  "wax",
  "wbmp",
  "wdp",
  "weba",
  "webm",
  "webp",
  "whl",
  "wim",
  "wm",
  "wma",
  "wmv",
  "wmx",
  "woff",
  "woff2",
  "wrm",
  "wvx",
  "xbm",
  "xif",
  "xla",
  "xlam",
  "xls",
  "xlsb",
  "xlsm",
  "xlsx",
  "xlt",
  "xltm",
  "xltx",
  "xm",
  "xmind",
  "xpi",
  "xpm",
  "xwd",
  "xz",
  "z",
  "zip",
  "zipx"
];

const IMAGE_EXTENSIONS = ["gif", "png", "jpg", "ico", "webp", "svg"];
const VIDEO_EXTENSIONS = ["mp4", "mov"];

/**
 * Get the name of a file without the extension
 */
export function getBaseName(filename: string) {
  // Handle dotfiles
  if (filename.startsWith(".")) {
    return filename;
  }

  if (filename.indexOf(".") < 0) {
    return filename;
  }

  const segments = filename.split(".");
  segments.pop();

  return segments.join(".");
}

export function getExt(filename: string) {
  return filename.split(".").pop()!;
}

export function getMimeType(ext: string) {
  if (IMAGE_EXTENSIONS.includes(ext)) {
    if (ext === "svg") {
      return "image/svg+xml";
    }

    return `image/${ext}`;
  }

  if (VIDEO_EXTENSIONS.includes(ext)) {
    if (ext === "mov") {
      return "video/quicktime";
    }

    return `video/${ext}`;
  }

  return "text/html";
}

export function isImageFile(filename?: string): boolean {
  return checkExtList(filename, IMAGE_EXTENSIONS);
}

export function isVideoFile(filename?: string): boolean {
  return checkExtList(filename, VIDEO_EXTENSIONS);
}

export function isBinaryFile(filename?: string): boolean {
  return checkExtList(filename, BINARY_EXTENSIONS);
}

function checkExtList(filename: string | undefined, list: string[]): boolean {
  if (!filename) {
    return false;
  }

  if (filename === "/dev/null") {
    return false;
  }

  const lower = filename.toLowerCase();
  const ext = getExt(lower);
  return list.includes(ext);
}
