
































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Avatar from "@/components/elements/Avatar.vue";

import AuthModule from "../../store/modules/auth";
import { itemSlug } from "../../model/inbox";

import { Review, ReviewStatus } from "../../../../shared/types";
import * as typeUtils from "../../../../shared/typeUtils";

@Component({
  components: {
    Avatar
  }
})
export default class InboxItem extends Vue {
  @Prop() public item!: Review;

  private authModule = getModule(AuthModule, this.$store);

  async mounted() {}

  public slugShort(item: Review) {
    return `${item.metadata.repo}#${item.metadata.number}`;
  }

  public slugLong(item: Review) {
    return itemSlug(item);
  }

  public statusClass(status: ReviewStatus) {
    return typeUtils.statusClass(status);
  }

  public statusIconName(status: ReviewStatus) {
    return typeUtils.statusIconName(status);
  }

  public formatTimestampLong(timestamp: number) {
    return typeUtils.formatTimestampLong(timestamp);
  }

  public formatTimestampShort(timestamp: number) {
    return typeUtils.formatTimestampShort(timestamp);
  }

  get needsAttention() {
    return this.assignedToMe && !this.isCompleted;
  }

  get isCompleted() {
    return this.item.state.closed;
  }

  get assignedToMe() {
    return this.item.state.assignees.includes(
      this.authModule.assertUser.username
    );
  }
}
