import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretRight,
  faCaretDown,
  faCirclePause,
  faAt,
  faPaperPlane,
  faCheck,
  faCodeBranch,
  faComment,
  faEye,
  faEyeSlash,
  faExclamation,
  faWandMagicSparkles,
  faKeyboard,
  faPlus,
  faMinus,
  faThumbsUp,
  faCircle,
  faXmark,
  faDoorOpen,
  faExclamationCircle,
  faBell,
  faListCheck,
  faMoon,
  faClockRotateLeft,
  faGear,
  faAnglesRight,
  faAnglesUp,
  faAnglesDown,
  faBuilding,
  faUser,
  faArrowUpRight,
  faArrowRight,
  faArrowUpRightFromSquare,
  faTrashCan,
  faArrowsUpDown,
  faReply,
  faShieldCheck,
  faPencilAlt,
  faArrowsRotate,
  faClipboard,
  faEllipsis,
  faImage,
  faBold,
  faItalic,
  faLink,
  faCode,
  faUsers,
  faTableList,
  faEarthAmericas,
  faFilePlusMinus,
  faPaintBrush,
  faPlay,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-regular-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export function initIconLibrary() {
  Vue.component("font-awesome-icon", FontAwesomeIcon);

  library.add(
    // Regular icons
    faCaretRight,
    faCaretDown,
    faCirclePause,
    faAt,
    faPaperPlane,
    faCheck,
    faCodeBranch,
    faComment,
    faEye,
    faEyeSlash,
    faExclamation,
    faWandMagicSparkles,
    faKeyboard,
    faPlus,
    faMinus,
    faThumbsUp,
    faCircle,
    faXmark,
    faDoorOpen,
    faExclamationCircle,
    faBell,
    faListCheck,
    faMoon,
    faClockRotateLeft,
    faGear,
    faAnglesRight,
    faAnglesUp,
    faAnglesDown,
    faBuilding,
    faUser,
    faArrowUpRight,
    faArrowRight,
    faArrowUpRightFromSquare,
    faTrashCan,
    faArrowsUpDown,
    faReply,
    faShieldCheck,
    faPencilAlt,
    faArrowsRotate,
    faClipboard,
    faEllipsis,
    faImage,
    faBold,
    faItalic,
    faLink,
    faCode,
    faQuoteRight,
    faUsers,
    faTableList,
    faEarthAmericas,
    faFilePlusMinus,
    faPaintBrush,
    faPlay,
    faChevronLeft,
    faChevronRight,

    // Brand icons
    faGithub
  );
}
