// A map of unicode emoji to an array of keywords
const emojiKeywordSet: Record<string, string[]> = require("emojilib");

type EmojiData = {
  name: string;
  keywords: string[];
};

// Build a map of unicode --> data
const emojiData: Record<string, EmojiData> = {};
for (const emoji in emojiKeywordSet) {
  const keywords = emojiKeywordSet[emoji];
  const name = keywords[0];

  emojiData[emoji] = {
    name,
    keywords
  };
}

// Add some special cases for common emoji
emojiData["🙂"].keywords.push(")");
emojiData["👍"].keywords.push("+1", "lgtm");
emojiData["👎"].keywords.push("-1");
emojiData["✅"].keywords.push("lgtm");
emojiData["🚢"].keywords.push("lgtm");
emojiData["🎉"].keywords.push("tada");
emojiData["🙏"].keywords.push("pray", "thanks");

// Build a reverse map of name --> unicode
const nameToEmoji: Record<string, string> = {};
Object.entries(emojiData).forEach(([e, data]) => {
  nameToEmoji[data.name] = e;
});

function scoreMatch(query: string, keywords: string[]) {
  const scores = keywords.map((k, index) => {
    const indexBonus = (keywords.length - index) / keywords.length;

    if (k === query) {
      return query.length + 2 + indexBonus;
    }

    if (k.startsWith(query)) {
      return query.length + 1 + indexBonus;
    }

    const segments = k.split("_");
    if (segments.some(s => s.startsWith(query))) {
      return query.length + indexBonus;
    }

    return 0;
  });

  return Math.max(...scores);
}

export function searchEmojiNames(q: string): string[] {
  const lowerQ = q.toLowerCase().trim();

  const matches = Object.entries(emojiData)
    .map(entry => {
      const [e, data] = entry;
      const score = scoreMatch(lowerQ, data.keywords);

      return {
        name: data.name,
        score
      };
    })
    .filter(x => x.score > 0)
    .sort((x, y) => y.score - x.score);

  console.log(q, matches);

  return matches.map(x => x.name);
}

export function getEmojiByName(n: string): string {
  return nameToEmoji[n];
}
