import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import {
  doc,
  collectionGroup,
  where,
  orderBy,
  limit,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  deleteDoc
} from "firebase/firestore";

import { firestore } from "@/plugins/firebase";
import * as api from "@/plugins/api";

import { Github, InstallationData, RepoData } from "../../../../shared/github";
import {
  BillingInfo,
  Org,
  Repo,
  Review,
  UserInvite
} from "../../../../shared/types";
import { Latch } from "../../../../shared/asyncUtils";
import {
  getOrDefault,
  reviewMetadatasEqual
} from "../../../../shared/typeUtils";
import {
  invitesPath,
  orgPath,
  orgsPath,
  repoPath
} from "../../../../shared/database";

type Listener = () => void;

const sortByTime = (a: Review, b: Review) => {
  return b.metadata.updated_at - a.metadata.updated_at;
};

@Module({
  name: "inbox"
})
export default class InboxModule extends VuexModule {
  public outgoing: Review[] = [];
  public incoming: Review[] = [];
  public assigned: Review[] = [];
  public cced: Review[] = [];

  public listeners: Listener[] = [];

  public installations: InstallationData[] = [];
  public billing: Record<string, BillingInfo> = {};
  public privateRepos: RepoData[] = [];

  public orgs: Record<string, Org> = {};
  public repositories: Record<string, Repo> = {};

  public invites: UserInvite[] = [];

  public login: string = "";

  @Action({ rawError: true })
  public async initialize(opts: { github: Github; login: string }) {
    this.context.commit("stopListening");
    this.context.commit("setLogin", opts.login);

    const latch = new Latch();

    const reviewsGroup = collectionGroup(firestore(), "reviews");

    // TODO(stop): This should probably filter on closed=false and have
    //             a second query for completed.
    latch.increment();
    const outgoingUnsub = onSnapshot(
      query(
        reviewsGroup,
        where("metadata.author", "==", opts.login),
        orderBy("metadata.updated_at", "desc"),
        limit(50)
      ),
      snap => {
        const reviews = snap.docs.map(d => d.data() as Review);
        this.context.commit("setOutgoing", reviews);
        latch.decrement();
      }
    );
    this.context.commit("addListener", outgoingUnsub);

    latch.increment();
    const incomingUnsub = onSnapshot(
      query(
        reviewsGroup,
        where("state.reviewers", "array-contains", opts.login),
        orderBy("metadata.updated_at", "desc"),
        limit(50)
      ),
      snap => {
        const reviews = snap.docs.map(d => d.data() as Review);
        this.context.commit("setIncoming", reviews);
        latch.decrement();
      }
    );
    this.context.commit("addListener", incomingUnsub);

    latch.increment();
    const assignedUnsub = onSnapshot(
      query(
        reviewsGroup,
        where("state.assignees", "array-contains", opts.login),
        orderBy("metadata.updated_at", "desc"),
        limit(50)
      ),
      snap => {
        const reviews = snap.docs.map(d => d.data() as Review);
        this.context.commit("setAssigned", reviews);
        latch.decrement();
      }
    );
    this.context.commit("addListener", assignedUnsub);

    latch.increment();
    const ccedUnsub = onSnapshot(
      query(
        reviewsGroup,
        where("state.ccs", "array-contains", opts.login),
        orderBy("metadata.updated_at", "desc"),
        limit(50)
      ),
      snap => {
        const reviews = snap.docs.map(d => d.data() as Review);
        this.context.commit("setCced", reviews);
        latch.decrement();
      }
    );
    this.context.commit("addListener", ccedUnsub);

    return latch.wait();
  }

  // TODO(polish): This should really be in a "settings" module but no matter what I did
  //               when I tried to make one I kept running into this error:
  //               https://github.com/championswimmer/vuex-module-decorators/issues/217
  @Action({ rawError: true })
  public async loadInstallations(opts: { github: Github }) {
    const latch = new Latch();

    const installations: InstallationData[] = await opts.github.listInstallationsForUser();
    this.context.commit("setInstallations", installations);

    if (installations.length === 0) {
      console.warn("No installations found for user");
      return;
    }

    for (const i of installations) {
      latch.increment();

      const orgRef = doc(firestore(), orgPath({ owner: i.account.login }));
      const orgUnsub = onSnapshot(orgRef, snap => {
        latch.decrement();

        if (snap.exists()) {
          this.context.commit("setOrg", { id: snap.id, org: snap.data() });
        } else {
          console.warn("Missing org", snap.id);
          this.context.commit("setOrg", { id: snap.id, org: undefined });
        }
      });

      this.context.commit("addListener", orgUnsub);
    }

    return latch.wait();
  }

  @Action({ rawError: true })
  public async loadReposForInstallations() {
    const db = firestore();

    const repositories: Record<string, Repo> = {};
    for (const i of this.installations) {
      const owner = i.account.login;

      for (const r of i.repositories) {
        const repoRef = doc(db, repoPath({ owner, repo: r.name }));

        try {
          const repoSnap = await getDoc(repoRef);
          if (repoSnap.exists()) {
            const repo = repoSnap.data() as Repo;
            repositories[`${owner}/${r.name}`] = repo;
          }
        } catch (e) {
          console.error(
            `Error loading repo ${owner}/${r.name} from installation ${i.id}`,
            e
          );
        }
      }
    }

    this.context.commit("setRepositories", repositories);
  }

  @Action({ rawError: true })
  public async loadInvites(opts: { uid: string }) {
    const db = firestore();
    const invitesRef = collection(db, invitesPath());
    const q = query(
      invitesRef,
      where("type", "==", "user"),
      where("invited_by", "==", opts.uid)
    );

    const snap = await getDocs(q);
    const invites = snap.docs.map(d => d.data() as UserInvite);

    this.context.commit("setInvites", invites);
  }

  @Action({ rawError: true })
  public async addInvite(opts: { invite: UserInvite }) {
    const db = firestore();
    const invitesRef = collection(db, invitesPath());

    // Check if the invite already exists
    const q = query(
      invitesRef,
      where("type", "==", "user"),
      where("email", "==", opts.invite.email),
      where("invited_by", "==", opts.invite.invited_by)
    );
    const snap = await getDocs(q);
    if (!snap.empty) {
      return;
    }

    await addDoc(invitesRef, opts.invite);

    this.context.commit("setInvites", [opts.invite, ...this.invites]);
  }

  @Action({ rawError: true })
  public async deleteInvite(opts: { email: string; uid: string }) {
    const db = firestore();
    const invitesRef = collection(db, invitesPath());

    const q = query(
      invitesRef,
      where("type", "==", "user"),
      where("email", "==", opts.email),
      where("invited_by", "==", opts.uid)
    );

    const snap = await getDocs(q);
    for (const d of snap.docs) {
      await deleteDoc(d.ref);
    }

    this.context.commit(
      "setInvites",
      this.invites.filter(i => i.email !== opts.email)
    );
  }

  @Action({ rawError: true })
  public async toggleAutoReview(opts: {
    org: string;
    repo?: string;
    val: boolean;
  }) {
    console.log("toggleAutoReview", JSON.stringify(opts));
    const db = firestore();
    if (opts.repo) {
      const repoRef = doc(db, repoPath({ owner: opts.org, repo: opts.repo }));
      updateDoc(repoRef, { auto_review: opts.val });
    } else {
      const orgRef = doc(db, orgPath({ owner: opts.org }));
      updateDoc(orgRef, { auto_review: opts.val });
    }
  }

  @Action({ rawError: true })
  public async toggleResolveThreadsByDefault(opts: {
    org: string;
    repo: string;
    val: boolean;
  }) {
    console.log("toggleResolveThreadsByDefault", JSON.stringify(opts));
    const db = firestore();
    const repoRef = doc(db, repoPath({ owner: opts.org, repo: opts.repo }));
    updateDoc(repoRef, { default_resolve_threads: opts.val });
  }

  @Action({ rawError: true })
  public async loadUsageForInstallations() {
    for (const installation of this.installations) {
      const org = installation.account.login;

      try {
        const info = await api.get(`/internal/orgs/${org}/usage`);
        this.context.commit("setBillingInfo", { org, info });
      } catch (e) {
        console.error("Error loading usage for org", org, e);
      }
    }
  }

  @Mutation
  public setInstallations(installations: InstallationData[]) {
    this.installations = installations;
  }

  @Mutation
  public setRepositories(repositories: Record<string, Repo>) {
    this.repositories = repositories;
  }

  @Mutation
  public setOrg(opts: { id: string; org: Org | undefined }) {
    Vue.set(this.orgs, opts.id, opts.org);
  }

  @Mutation
  public setPrivateRepos(repos: RepoData[]) {
    this.privateRepos = repos;
  }

  @Mutation
  public setBillingInfo(opts: { org: string; info: BillingInfo }) {
    Vue.set(this.billing, opts.org, opts.info);
  }

  @Mutation
  public addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  @Mutation
  public stopListening() {
    for (const l of this.listeners) {
      l();
    }

    this.listeners = [];
  }

  @Mutation
  public setLogin(login: string) {
    this.login = login;
  }

  @Mutation
  public setIncoming(incoming: Review[]) {
    this.incoming = incoming;
  }

  @Mutation
  public setOutgoing(outgoing: Review[]) {
    this.outgoing = outgoing;
  }

  @Mutation
  public setAssigned(assigned: Review[]) {
    this.assigned = assigned;
  }

  @Mutation
  public setCced(cced: Review[]) {
    this.cced = cced;
  }

  @Mutation
  public setInvites(invites: UserInvite[]) {
    this.invites = invites;
  }

  get assignedReviews() {
    return this.assigned.filter(r => !r.state.closed).sort(sortByTime);
  }

  get outgoingReviews() {
    return this.outgoing
      .filter(r => !r.state.closed)
      .filter(r => !r.state.assignees.includes(this.login))
      .sort(sortByTime);
  }

  get reviewingReviews() {
    return this.incoming
      .filter(r => !r.state.closed)
      .filter(r => !r.state.assignees.includes(this.login))
      .sort(sortByTime);
  }

  get ccedReviews() {
    return this.cced
      .filter(r => !r.state.closed)
      .filter(r => !r.state.reviewers.includes(this.login))
      .sort(sortByTime);
  }

  get finished() {
    const arrays = [this.incoming, this.cced, this.outgoing];
    const combined: Review[] = [];
    for (const arr of arrays) {
      for (const r of arr) {
        if (!combined.find(x => reviewMetadatasEqual(x.metadata, r.metadata))) {
          combined.push(r);
        }
      }
    }

    return combined.filter(r => r.state.closed).sort(sortByTime);
  }

  get repoAutoReviewEnabled() {
    return (owner: string, name: string) => {
      const slug = `${owner}/${name}`;
      const repo = this.repositories[slug];

      return getOrDefault(repo?.auto_review, false);
    };
  }

  get repoDefaultResolveThreads() {
    return (owner: string, name: string) => {
      const slug = `${owner}/${name}`;
      const repo = this.repositories[slug];

      return getOrDefault(repo?.default_resolve_threads, true);
    };
  }

  get orgAutoReviewEnabled() {
    return (owner: string) => {
      return !!this.orgs[owner]?.auto_review;
    };
  }
}
