import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from "firebase/firestore";

import {
  repoMovesPath,
  repoPath,
  reviewHistoriesPath
} from "../../../shared/database";
import {
  Repo,
  RepoMove,
  ReviewAction,
  ReviewIdentifier
} from "../../../shared/types";
import { getEmptyHistory } from "../../../shared/typeUtils";
import { firestore } from "./firebase";

export async function loadRepoMove(opts: {
  owner: string;
  repo: string;
}): Promise<RepoMove | undefined> {
  // Query for the latest matching repo move, if any
  const ref = collection(firestore(), repoMovesPath());
  const q = query(
    ref,
    where("from.owner", "==", opts.owner),
    where("from.repo", "==", opts.repo),
    orderBy("timestamp", "desc"),
    limit(1)
  );

  const snap = await getDocs(q);
  if (snap.docs.length > 0) {
    const move: RepoMove = snap.docs[0].data() as RepoMove;
    return move;
  }
}

export async function addReviewHistory(
  id: ReviewIdentifier,
  username: string,
  actions: ReviewAction[]
) {
  const historiesRef = collection(firestore(), reviewHistoriesPath(id));
  const history = getEmptyHistory(username, actions, id);
  return addDoc(historiesRef, history);
}
