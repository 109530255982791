const KNOWN_TAGS: string[] = [];

// Only record 1 / 50 samples
const SAMPLE_FACTOR = 1 / 50;

function isPromise(value: any): boolean {
  return !!(
    value &&
    value.then &&
    typeof value.then === "function" &&
    value.constructor.name === "Promise"
  );
}

export const TraceMethod = (customTag?: string) => (
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => {
  // Only sample some of the time
  if (Math.random() > SAMPLE_FACTOR) {
    return;
  }

  const tag = customTag ? customTag : propertyKey;

  // TODO: Why would descriptor be undefined?
  if (!descriptor) {
    return;
  }

  const originalMethod = descriptor.value;
  descriptor.value = function(...args: any[]) {
    startTrace(tag);
    const result = originalMethod.apply(this, args);
    if (isPromise(result)) {
      (result as Promise<unknown>).finally(() => endTrace(tag));
    } else {
      endTrace(tag);
    }
    return result;
  };
};

function startTrace(tag: string) {
  KNOWN_TAGS.push(tag);
  window.performance.mark(`${tag}-start`);
}

function endTrace(tag: string) {
  const ts = `${tag}-start`;
  const te = `${tag}-end`;

  window.performance.mark(te);
  window.performance.measure(tag, ts, te);
  window.performance.clearMarks(ts);
  window.performance.clearMarks(te);
}
