






















import { Component, Vue, Prop } from "vue-property-decorator";
import * as typeUtils from "../../../../shared/typeUtils";
import { CheckStatus } from "../../../../shared/github";
import { ReviewStatus } from "../../../../shared/types";

@Component({
  components: {}
})
export default class PullRequestCheck extends Vue {
  @Prop() check!: CheckStatus;

  public getCheckDisplay(check: CheckStatus) {
    // Special case for "neutral" results like a skipped check
    const successOrFailure = check.successful || check.failed;
    const neutral = check.completed && !successOrFailure;
    if (neutral) {
      return {
        text: "text-app-dim",
        icon: typeUtils.statusIconName(ReviewStatus.CLOSED_UNMERGED)
      };
    }

    const status = !check.completed
      ? ReviewStatus.NEEDS_REVIEW
      : check.successful
      ? ReviewStatus.APPROVED
      : ReviewStatus.CLOSED_UNMERGED;

    const classes = typeUtils.statusClass(status);
    const icon = typeUtils.statusIconName(status);

    return {
      text: classes.text,
      icon
    };
  }
}
