const Prism = require("prismjs");
/**
 * Map of file extensions to Prism lang.
 */
const LANG_MAP: Record<string, string> = {
  c: "c",
  cc: "cpp",
  cjs: "javascript",
  cpp: "cpp",
  cs: "csharp",
  csharp: "csharp",
  css: "css",
  Dockerfile: "docker",
  ejs: "ejs",
  erb: "erb",
  go: "go",
  gqp: "graphql",
  gradle: "groovy",
  h: "clike",
  hs: "haskell",
  html: "markup",
  java: "java",
  js: "javascript",
  json: "javascript",
  jsx: "jsx",
  kt: "kotlin",
  less: "less",
  m: "objectivec",
  mjs: "javascript",
  ms: "markdown",
  php: "php",
  proto: "protobuf",
  py: "python",
  rb: "ruby",
  rules: "firestore-security-rules",
  rust: "rust",
  scala: "scala",
  scss: "sass",
  sh: "bash",
  sql: "sql",
  svg: "markup",
  swift: "swift",
  ts: "typescript",
  tsx: "tsx",
  vue: "html",
  xml: "markup",
  yaml: "yaml",
  yml: "yaml"
};

/**
 * Aliases for languages with multiple common names.
 * Ex: ```go and ```golang should be the same
 */
const LANG_ALIASES: Record<string, string> = {
  golang: "go"
};

// Require the prism plugin for each language we support
// See: https://github.com/PrismJS/prism/issues/593
require("prismjs/components/prism-bash.js");
require("prismjs/components/prism-c.js");
require("prismjs/components/prism-clike.js");
require("prismjs/components/prism-cpp.js");
require("prismjs/components/prism-csharp.js");
require("prismjs/components/prism-css.js");
require("prismjs/components/prism-docker.js");
require("prismjs/components/prism-ejs.js");
require("prismjs/components/prism-erb.js");
require("prismjs/components/prism-firestore-security-rules.js");
require("prismjs/components/prism-go.js");
require("prismjs/components/prism-graphql.js");
require("prismjs/components/prism-groovy.js");
require("prismjs/components/prism-haskell.js");
require("prismjs/components/prism-java.js");
require("prismjs/components/prism-javascript.js");
require("prismjs/components/prism-jsx.js");
require("prismjs/components/prism-kotlin.js");
require("prismjs/components/prism-less.js");
require("prismjs/components/prism-markdown.js");
require("prismjs/components/prism-markup.js");
require("prismjs/components/prism-markup-templating.js");
require("prismjs/components/prism-objectivec.js");
require("prismjs/components/prism-php.js");
require("prismjs/components/prism-protobuf.js");
require("prismjs/components/prism-python.js");
require("prismjs/components/prism-ruby.js");
require("prismjs/components/prism-rust.js");
require("prismjs/components/prism-sass.js");
require("prismjs/components/prism-scala.js");
require("prismjs/components/prism-sql.js");
require("prismjs/components/prism-swift.js");
require("prismjs/components/prism-tsx.js");
require("prismjs/components/prism-typescript.js");
require("prismjs/components/prism-yaml.js");

export function getPrismLangCode(lang: string): string {
  const alias = LANG_ALIASES[lang];
  if (alias) {
    return alias;
  }

  return lang;
}

export function getFileLang(filename: string): string {
  const segments: string[] = filename.split(".");
  const ext: string = segments[segments.length - 1];

  const suggested = LANG_MAP[ext];
  if (suggested) {
    return suggested;
  }

  return "markup";
}
