














































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import UIModule from "../../store/modules/ui";

@Component
export default class FooterBar extends Vue {
  uiModule = getModule(UIModule, this.$store);

  public showHotkeyModal() {
    console.log("showHotKeymodal");
    this.uiModule.setShowHotkeyModal(true);
  }
}
