












import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class FourOhFour extends Vue {
  async mounted() {}

  get title() {
    return "CodeApprove";
  }
}
