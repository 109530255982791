import Vue from "vue";
import { Side, THREAD_LINE_DISCUSSION } from "../../../shared/types";

const BUS = new Vue();

type Callback<T> = (arg: T) => void;

export const ADD_COMMENT_EVENT = "add-comment";

export interface NewThreadAddCommentEvent {
  type: "new_thread_add_comment";
  content: string;
  resolve?: boolean;

  side: Side;
  sha: string;
  file: string;
  line: number;
  lineContent: string;

  proposedThreadId?: string;
  reviewSummary?: boolean;
}

export interface ReplyAddCommentEvent {
  type: "reply_add_comment";
  threadId: string;
  content: string;
  resolve?: boolean;
}

export type AddCommentEvent = NewThreadAddCommentEvent | ReplyAddCommentEvent;

export const NEW_COMMENT_EVENT = "new-comment";
export interface NewCommentEvent {
  threadId: string;
}

export const NEW_THREAD_EVENT = "new-thread";
export interface NewThreadEvent {
  threadId: string;
}

export const PAGE_VISIBILITY_EVENT = "page-visibility";
export interface PageVisibilityEvent {
  visible: boolean;
}

export const REQUEST_REFRESH_EVENT = "request-refresh";
export interface RequestRefreshEvent {}

export const SEND_PENDING_ACTIONS_EVENT = "send-pending-actions";
export interface SendPendingActionsEvent {}

export const FINISH_REVIEW_EVENT = "finish-review";
export interface FinishReviewEvent {
  approved: boolean;
  comment?: {
    text: string;
    resolved: boolean;
  };
}

export const CUSTOM_ERROR_EVENT = "custom-error";
export interface CustomErrorEvent {
  original: any;
  message: string;
}

// Note: emitted via Vue elements, not via the event bus
export const LINE_CLICK_EVENT = "lineclick";

export function emit<T>(event: string, data: T) {
  BUS.$emit(event, data);
}

export function on<T>(event: string, fn: Callback<T>) {
  BUS.$on(event, fn);
}

export function off<T>(event: string, fn: Callback<T>) {
  BUS.$off(event, fn);
}

export function getNewDiscussionEvent(
  e: Partial<NewThreadAddCommentEvent> & { content: string }
): NewThreadAddCommentEvent {
  // A PR-level comment ("discussion") has no file and is on line 0
  return {
    ...e,
    type: "new_thread_add_comment",
    sha: "",
    side: "right",
    file: "",
    line: THREAD_LINE_DISCUSSION,
    lineContent: ""
  };
}
