import {
  User,
  Org,
  Repo,
  Installation,
  Review,
  Thread,
  ReviewHistory,
  UserRepos,
  ReviewUserData,
  RepoMove,
  SignUpRequest,
  Invite,
} from "./types";

// TODO(polish): Why don't these type aliases actually do anything?
export type CollectionPath<T> = string;
export type DocumentPath<T> = string;

export function userPath(opts: { id: string }): DocumentPath<User> {
  return `/users/${opts.id}`;
}

export function userReposPath(opts: { id: string }): DocumentPath<UserRepos> {
  return `${userPath(opts)}/metadata/repos`;
}

export function usersPath(): CollectionPath<User> {
  return `/users`;
}

export function orgsPath(): CollectionPath<Org> {
  return `/orgs`;
}

export function orgPath(opts: { owner: string }): DocumentPath<Org> {
  return `/orgs/${opts.owner}`;
}

export function reposPath(opts: { owner: string }): CollectionPath<Repo> {
  return `${orgPath(opts)}/repos`;
}

export function repoPath(opts: {
  owner: string;
  repo: string;
}): DocumentPath<Repo> {
  return `${reposPath(opts)}/${opts.repo}`;
}

export function reviewsPath(opts: {
  owner: string;
  repo: string;
}): CollectionPath<Review> {
  return `${repoPath(opts)}/reviews`;
}

export function reviewPath(opts: {
  owner: string;
  repo: string;
  number: number;
}): DocumentPath<Review> {
  // TODO(stop): How bad is the hotspotting caused by using the number as the doc key?
  return `${repoPath(opts)}/reviews/${opts.number}`;
}

export function reviewHistoriesPath(opts: {
  owner: string;
  repo: string;
  number: number;
}): CollectionPath<ReviewHistory> {
  return `${repoPath(opts)}/reviews/${opts.number}/history`;
}

export function threadsPath(opts: {
  owner: string;
  repo: string;
  number: number;
}): CollectionPath<Thread> {
  return `${reviewPath(opts)}/threads`;
}

export function commentsPath(opts: {
  owner: string;
  repo: string;
  number: number;
}): CollectionPath<Comment> {
  return `${reviewPath(opts)}/comments`;
}

export function reviewUserDatasPath(opts: {
  owner: string;
  repo: string;
  number: number;
}): CollectionPath<ReviewUserData> {
  return `${reviewPath(opts)}/users`;
}

export function repoMovesPath(): CollectionPath<RepoMove> {
  return `/repomoves`;
}

export function repoMovePath(opts: { id: string }): DocumentPath<RepoMove> {
  return `${repoMovesPath()}/${opts.id}`;
}

export function requestsPath(): CollectionPath<SignUpRequest> {
  return `/requests`;
}

export function invitesPath(): CollectionPath<Invite> {
  return `/invites`;
}
