


































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Avatar from "@/components/elements/Avatar.vue";
import ReviewModule from "@/store/modules/review";
import { ReviewHistory } from "../../../../shared/types";
import {
  findLastIndex,
  formatTimestampShort,
  getHistorySummaryText
} from "../../../../shared/typeUtils";

@Component({
  components: { Avatar }
})
export default class PullRequestTimeline extends Vue {
  @Prop() histories!: ReviewHistory[];

  private reviewModule = getModule(ReviewModule, this.$store);

  selecting = false;
  selectingStart = -1;
  selectingEnd = -1;

  get sortedHistories() {
    return [...this.histories]
      .sort((a, b) => a.timestamp - b.timestamp)
      .filter(h => {
        // Always allow multi-action histories or zero-action histories.
        if (h.actions.length !== 1) {
          return true;
        }

        // Ignore simple people actions
        const onlyActionType = h.actions[0].type;
        if (
          [
            "add_assignee",
            "remove_assignee",
            "add_cc",
            "remove_cc",
            "add_reviewer",
            "remove_reviewer"
          ].includes(onlyActionType)
        ) {
          return false;
        }

        return true;
      });
  }

  get window() {
    const { viewSinceTime, viewUntilTime } = this.reviewModule.viewState;

    // Note: the +1s here are because 0 is the 'start' dot, the histories are 1..n
    let start = 0;
    let end = this.sortedHistories.length + 1;

    if (viewSinceTime > 0) {
      start =
        this.sortedHistories.findIndex(x => x.timestamp >= viewSinceTime) + 1;
    }

    if (viewUntilTime > 0) {
      end =
        findLastIndex(this.sortedHistories, x => x.timestamp <= viewUntilTime) +
        1;
    }

    return { start, end };
  }

  public getDotClass(index: number) {
    if (this.selecting) {
      if (this.isSelected(index)) {
        return "border-green-500";
      }
    } else if (this.isIncluded(index)) {
      return "border-brand-500";
    }

    return "border-gray-600 opacity-50";
  }

  public getLineClass(index: number) {
    if (this.selecting) {
      return "bg-app-6";
    }

    if (this.isIncluded(index) && this.isIncluded(index + 1)) {
      return "bg-brand-500";
    }

    return "bg-app-6";
  }

  public isSelected(index: number) {
    return (
      this.selecting &&
      (this.selectingStart === index || this.selectingEnd === index)
    );
  }

  public isIncluded(index: number) {
    return index >= this.window.start && index <= this.window.end;
  }

  public getTimestampLabel(history: ReviewHistory) {
    return `${formatTimestampShort(history.timestamp)}`;
  }

  public getTooltip(history: ReviewHistory) {
    return getHistorySummaryText(history);
  }

  private getTimestamp(index: number) {
    // Start of time
    if (index <= 0) {
      return 0;
    }

    // End of time
    if (index > this.sortedHistories.length) {
      return 0;
    }

    // We subtract 1 here because the histories are 1..n
    return this.sortedHistories[index - 1].timestamp;
  }

  private startSelecting(index: number) {
    this.selecting = true;
    this.selectingStart = index;
    this.selectingEnd = -1;
  }

  private endSelecting(index: number) {
    // Don't allow a single dot range
    if (index === this.selectingStart) {
      return;
    }

    // Don't allow backwards ranges
    if (index < this.selectingStart) {
      this.selectingEnd = this.selectingStart;
      this.selectingStart = index;
    } else {
      this.selectingEnd = index;
    }

    const start = this.getTimestamp(this.selectingStart);
    const end = this.getTimestamp(this.selectingEnd);

    console.log("PullRequestTimeline#change", {
      start,
      end
    });

    // Emit event up
    this.$emit("change", {
      start,
      end
    });

    // Reset
    this.resetState();
  }

  public onDotClick(index: number) {
    if (!this.selecting) {
      this.startSelecting(index);
    } else {
      this.endSelecting(index);
    }
  }

  public resetState() {
    this.selecting = false;
    this.selectingStart = -1;
    this.selectingEnd = -1;
  }
}
