import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth";
import UIModule from "@/store/modules/ui";

import Home from "@/components/pages/Home.vue";
import SignIn from "@/components/pages/SignIn.vue";
import PullRequest from "@/components/pages/PullRequest.vue";
import Inbox from "@/components/pages/Inbox.vue";
import Pricing from "@/components/pages/Pricing.vue";
import FourOhFour from "@/components/pages/FourOhFour.vue";
import About from "@/components/pages/About.vue";
import TermsOfService from "@/components/pages/TermsOfService.vue";
import PrivacyPolicy from "@/components/pages/PrivacyPolicy.vue";
import Settings from "@/components/pages/Settings.vue";
import Interest from "@/components/pages/Interest.vue";
import Browse from "@/components/pages/Browse.vue";
import Create from "@/components/pages/Create.vue";
import SignOut from "@/components/pages/SignOut.vue";

import store from "@/store";
import * as cookies from "../plugins/cookies";

const authModule = getModule(AuthModule, store);
const uiModule = getModule(UIModule, store);

authModule.restoreFromLocalStorage();

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: Home },
    { path: "/signin", component: SignIn },
    {
      path: "/pr/:owner/:repo/:number",
      name: "PullRequest",
      component: PullRequest,
      meta: {
        auth: true,
        hotkeys: true
      }
    },
    {
      path: "/:owner/:repo/pull/:number",
      redirect: to => {
        const { owner, repo, number } = to.params;
        return { path: `/pr/${owner}/${repo}/${number}` };
      }
    },
    {
      path: "/inbox",
      name: "Inbox",
      component: Inbox,
      meta: {
        auth: true
      }
    },
    {
      path: "/browse",
      name: "Browse",
      component: Browse,
      meta: {
        auth: true
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        auth: true
      }
    },
    {
      path: "/create",
      name: "Create",
      component: Create,
      meta: {
        auth: true
      }
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/about",
      name: "About",
      component: About
    },
    {
      path: "/terms-of-service",
      name: "TermsOfService",
      component: TermsOfService
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy
    },
    {
      path: "/interest",
      name: "Interest",
      component: Interest
    },
    {
      path: "/signout",
      name: "SignOut",
      component: SignOut
    },
    {
      path: "/404",
      name: "404",
      component: FourOhFour
    },
    {
      path: "*",
      component: FourOhFour
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // When the path changes (not just query params) scroll to the top
    if (to.path !== from.path) {
      // Scroll to top
      // See: https://router.vuejs.org/guide/advanced/scroll-behavior.html
      return { x: 0, y: 0 };
    }

    return savedPosition;
  }
});

router.beforeEach((to, from, next) => {
  uiModule.endLoading();
  uiModule.clearNonErrors();

  // When signed in, the home page is the inbox
  if (to.path === "/" && authModule.signedIn) {
    next({ path: "/inbox" });
    return;
  }

  if (to.meta && to.meta.auth && !authModule.signedIn) {
    console.log("Not signed in, blocking route: ", to.fullPath);
    Vue.$cookies.set(cookies.SIGNIN_PATH, to.path);
    next({ path: "/signin" });
    return;
  }

  next();
});

router.afterEach((to, from) => {
  // Re-set favicon
  const link = document.querySelector("link[rel~='icon']");
  if (link) {
    (link as any).href = "/favicon.ico";
  }
});

export default router;
