var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chunk",class:{
    'select-lock-left': _vm.selectLock === 'left',
    'select-lock-right': _vm.selectLock === 'right'
  }},_vm._l((_vm.lines),function(l,index){return _c('div',{key:_vm.getLineKey(l),staticClass:"diffline text-xs relative w-full cursor-text bg-app-2",attrs:{"id":_vm.getLineKey(l)}},[_c('div',{staticClass:"shim bg-white"}),(_vm.showLeft(l))?_c('div',{staticClass:"line-side-left line-side ib relative align-top",class:_vm.sideClassNames(l, 'left'),on:{"mousedown":function () { return _vm.setSelectLock('left'); }}},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell",class:_vm.lineNumberClassNames(l.rendered.left),on:{"click":function($event){return _vm.onLineNumberClicked('left', l.rendered.left.number)}}},[_c('code',{staticClass:"line-number"},[_vm._v(_vm._s(_vm.lineNumberString(l.rendered.left)))]),(_vm.mode === 'unified')?_c('code',{staticClass:"line-number"},[_vm._v(_vm._s(_vm.lineNumberString(l.rendered.right)))]):_vm._e()]),_c('div',{staticClass:"table-cell relative line-marker-container"},[_c('code',{staticClass:"line-marker"},[_vm._v(_vm._s(l.rendered.left.marker))]),(_vm.renderCommentButton(index, 'left'))?_c('button',{staticClass:"comment-button",on:{"click":function () { return _vm.setDrafting(l.rendered.left.number, 'left', true); }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'comment'],"size":"sm"}})],1):_vm._e()]),_c('div',{staticClass:"table-cell"},[_c('pre',[_c('code',{staticClass:"line-content",attrs:{"id":("cl-" + (l.index))},domProps:{"innerHTML":_vm._s(
                _vm.stripNewlines(_vm.highlightedLines.left[index] || l.rendered.left.content)
              )}})])])])]),(_vm.showComments(index, 'left'))?_c('div',{staticClass:"w-full"},[_c('CommentThread',{class:_vm.commentClassNames(),attrs:{"side":"left","line":l.rendered.left.number,"threadId":_vm.getThreadId(l, 'left')},on:{"cancel":function($event){return _vm.onCommentCancel(l.rendered.left.number, 'left')},"comment":function (e) { return _vm.onCommentEvent(l, e); },"settled":function($event){return _vm.onCommentSettled(l.rendered.left.number, 'left')}}})],1):_vm._e()]):_vm._e(),(_vm.showRight(l))?_c('div',{staticClass:"line-side-right line-side ib relative align-top",class:_vm.sideClassNames(l, 'right'),on:{"mousedown":function () { return _vm.setSelectLock('right'); }}},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell",class:_vm.lineNumberClassNames(l.rendered.right),on:{"click":function($event){return _vm.onLineNumberClicked('right', l.rendered.right.number)}}},[(_vm.mode === 'unified')?_c('code',{staticClass:"line-number"},[_vm._v(_vm._s(_vm.lineNumberString(l.rendered.left)))]):_vm._e(),_c('code',{staticClass:"line-number"},[_vm._v(_vm._s(_vm.lineNumberString(l.rendered.right)))])]),_c('div',{staticClass:"table-cell relative line-marker-container"},[_c('code',{staticClass:"line-marker"},[_vm._v(_vm._s(l.rendered.right.marker))]),(_vm.renderCommentButton(index, 'right'))?_c('button',{staticClass:"comment-button",on:{"click":function () { return _vm.setDrafting(l.rendered.right.number, 'right', true); }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'comment'],"size":"sm"}})],1):_vm._e()]),_c('div',{staticClass:"table-cell"},[_c('pre',[_c('code',{staticClass:"line-content",attrs:{"id":("cr-" + (l.index))},domProps:{"innerHTML":_vm._s(
                _vm.stripNewlines(_vm.highlightedLines.right[index] || l.rendered.right.content)
              )}})])])])]),(_vm.showComments(index, 'right'))?_c('div',{staticClass:"w-full"},[_c('CommentThread',{class:_vm.commentClassNames(),attrs:{"side":"right","line":l.rendered.right.number,"threadId":_vm.getThreadId(l, 'right')},on:{"cancel":function($event){return _vm.onCommentCancel(l.rendered.right.number, 'right')},"comment":function (e) { return _vm.onCommentEvent(l, e); },"settled":function($event){return _vm.onCommentSettled(l.rendered.right.number, 'right')}}})],1):_vm._e()]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }