import { Octokit } from "@octokit/rest";
import { graphql } from "@octokit/graphql";
import {
  UsersGetAuthenticatedResponseData,
  AppsGetRepoInstallationResponseData,
} from "@octokit/types";

import * as octocache from "./octocache";
import { GithubConfig, UserSearchItem } from "./types";
import { tokenNearlyExpired, userMatches } from "./typeUtils";
import { ParsedDiff, parseDiffString } from "./diffUtils";

const PREVIEWS = ["machine-man-preview"];

export interface PullRequestNode {
  title: string;
  number: number;
  repository: {
    owner: {
      login: string;
    };
    name: string;
  };
  closed: boolean;
  merged: boolean;
  updatedAt: string;
}

export type InstallationStatus =
  | NoInstallationStatus
  | SuccessfulInstallationStatus;

export interface NoInstallationStatus {
  installed: false;
}

export interface SuccessfulInstallationStatus {
  installed: true;
  repositories: {
    full_name: string;
  }[];
}

export interface RepoSearchItem {
  id: string;
  full_name: string;
  owner: string;
  name: string;
}

export interface CheckStatus {
  id: number;
  name: string;
  html_url: string;
  completed: boolean;
  successful: boolean;
  failed: boolean;
  skipped: boolean;
}

export interface CommitStatus {
  merge: {
    mergeable: boolean | null;
  };
  checks: CheckStatus[];
}

export interface RepoData {
  full_name: string;
  html_url: string;
  private: boolean;
}

export interface InstallationData {
  id: number;
  html_url: string;
  account: {
    login: string;
  };
  target_type: string;
  repositories: {
    name: string;
  }[];
}

export interface GqlCursor {
  endCursor: string;
  hasNextPage: boolean;
}

export interface UserNode {
  login: string;
  name: string | null;
}

export interface RepoCollaboratorsResponse {
  repository: {
    collaborators: {
      nodes: UserNode[];
    };
  };
}

export interface SearchAllUsersResponse {
  search: {
    nodes: UserNode[];
  };
}

export interface RecentBranchNode {
  name: string;
  target: {
    author?: {
      user?: {
        login: string;
      };
    };
    committedDate?: string;
  };
}

export interface RecentBranchesResponse {
  repository: {
    refs: {
      pageInfo: GqlCursor;
      nodes: RecentBranchNode[];
    };
  };
}

export interface PullRequestCommitNode {
  commit: {
    oid: string;
    message: string;
    committedDate: string | null;
    pushedDate: string | null;
    parents: {
      totalCount: number;
    };
  };
}

export interface PullRequestCommitsResponse {
  repository: {
    pullRequest: {
      timelineItems: {
        nodes: PullRequestCommitNode[];
      };
    };
  };
}

export interface PullRequestBodyHTMLResponse {
  repository: {
    pullRequest: {
      bodyHTML: string;
    };
  };
}

export interface AuthDelegate {
  getExpiry(): number;
  getToken(): string;
  getAuthenticationHeader(): string;
  refreshAuth(): Promise<any>;
}

export class Github {
  public octokit!: Octokit;
  private gql: typeof graphql = graphql;

  constructor(
    private authDelegate: AuthDelegate,
    private githubConfig: GithubConfig
  ) {
    this.applyAuth(this.authDelegate);
  }

  private applyAuth(authDelegate: AuthDelegate) {
    const token = authDelegate.getToken();
    this.octokit = new Octokit({ auth: token, previews: PREVIEWS });
    this.gql = this.gql.defaults({
      headers: {
        authorization: `${authDelegate.getAuthenticationHeader()} ${token}`,
      },
    });
  }

  async assertAuth(): Promise<void> {
    // Refresh if it will expire soon
    if (tokenNearlyExpired(this.authDelegate.getExpiry())) {
      await this.authDelegate.refreshAuth();
      this.applyAuth(this.authDelegate);
    }
  }

  async assertAuthToken() {
    await this.assertAuth();
    return this.authDelegate.getToken();
  }

  async me(): Promise<UsersGetAuthenticatedResponseData> {
    const res = await this.octokit.users.getAuthenticated();
    return res.data;
  }

  getBotName(): string {
    return this.githubConfig.app_name + "[bot]";
  }

  async getUser(login: string) {
    await this.assertAuth();

    const res = await this.octokit.users.getByUsername({
      username: login,
    });

    return res.data;
  }

  async getUserEmails() {
    await this.assertAuth();

    const res = await this.octokit.users.listEmailsForAuthenticated();
    return res.data;
  }

  async searchUsers(
    owner: string | null,
    repo: string | null,
    prefix: string,
    preferCollaborators: boolean
  ): Promise<UserSearchItem[]> {
    await this.assertAuth();

    const res: UserSearchItem[] = [];

    // List collaborators from the repo (if possible)
    if (preferCollaborators && owner != null && repo != null) {
      const collabs: UserNode[] = await this.listCollaborators(
        owner,
        repo
      ).catch((e) => {
        // We just return nothing if we can't access this
        return [];
      });

      const items = collabs.map((c) => {
        return {
          id: c.login,
          login: c.login,
          name: c.name,
          collaborator: true,
        };
      });

      // Filter users for the prefix
      const collabsFiltered = items.filter((x) => userMatches(x, prefix));
      res.push(...collabsFiltered);

      // When we have collaborators that match, no need to show randoms
      if (collabsFiltered.length > 0 && preferCollaborators) {
        return res;
      }
    }

    // List random GitHub users
    const randomUsers = await this.searchAllUsers(prefix);
    const randomItems: UserSearchItem[] = randomUsers.map((u) => {
      return {
        id: u.login,
        login: u.login,
        name: u.name,
        collaborator: false,
      };
    });

    for (const i of randomItems) {
      if (!res.some((x) => x.login === i.login)) {
        res.push(i);
      }
    }

    return res;
  }

  async listUserRepos(): Promise<RepoSearchItem[]> {
    await this.assertAuth();

    const res = await octocache.call(
      "repos.listForAuthenticatedUser",
      this.octokit.repos.listForAuthenticatedUser,
      { sort: "pushed", per_page: 20 }
    );

    return res.map((r: any) => {
      return {
        id: r.full_name,
        full_name: r.full_name,
        owner: r.owner.login,
        name: r.name,
      };
    });
  }

  async searchRepos(prefix: string): Promise<RepoSearchItem[]> {
    await this.assertAuth();

    const searchRes = await this.octokit.search.repos({
      q: prefix,
      per_page: 5,
    });
    const repos = searchRes.data.items;

    return repos.map((r) => {
      return {
        id: r.full_name,
        full_name: r.full_name,
        owner: r.owner.login,
        name: r.name,
      };
    });
  }

  async getPullRequestMetadata(
    owner: string,
    repo: string,
    pull_number: number
  ) {
    await this.assertAuth();

    const res = await this.octokit.pulls.get({ owner, repo, pull_number });
    return res.data;
  }

  async getPullRequestBodyHTML(
    owner: string,
    repo: string,
    pull_number: number
  ) {
    const query = `{
      repository(owner: "${owner}", name: "${repo}") {
        pullRequest(number: ${pull_number}) {
          bodyHTML
        }
      }
    }`;

    const res: PullRequestBodyHTMLResponse = await octocache.callGql(
      "pr.bodyHTML",
      query,
      (q: string) => this.executeGql(this.gql(q))
    );

    return res;
  }

  async updatePullRequestBody(
    owner: string,
    repo: string,
    pull_number: number,
    body: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.pulls.update({
      owner,
      repo,
      pull_number,
      body,
    });

    return res.data;
  }

  async updatePullRequestTitle(
    owner: string,
    repo: string,
    pull_number: number,
    title: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.pulls.update({
      owner,
      repo,
      pull_number,
      title,
    });

    return res.data;
  }

  async getCommitStatusAndChecks(
    owner: string,
    repo: string,
    pull_number: number,
    ref: string
  ): Promise<CommitStatus> {
    await this.assertAuth();

    const metaRes = await this.getPullRequestMetadata(owner, repo, pull_number);
    const mergeable = metaRes.merged ? true : metaRes.mergeable;

    const merge = {
      mergeable,
    };

    const checksRes = await this.octokit.checks.listForRef({
      owner,
      repo,
      ref,
    });

    // For each check
    //   - status: Can be one of queued, in_progress, or completed.
    //   - conclusion: Required if you provide completed_at or a status of completed.
    //                 The final conclusion of the check. Can be one of success, failure, neutral, cancelled, skipped, timed_out, or action_required.
    //                 When the conclusion is action_required, additional details should be provided on the site specified by details_url.
    const checks: CheckStatus[] = checksRes.data.check_runs.map((c) => {
      const id = c.id;
      const name = c.name;
      const html_url = c.html_url;

      const completed = c.status === "completed";

      // "skipped" and "neutral" are neither success or failure
      const successful = c.conclusion === "success";
      const failed = [
        "failure",
        "cancelled",
        "timed_out",
        "action_required",
      ].includes(c.conclusion);
      const skipped = c.conclusion === "skipped";

      return {
        id,
        name,
        html_url,
        completed,
        successful,
        skipped,
        failed,
      };
    });

    return {
      checks,
      merge,
    };
  }

  async listCommits(owner: string, repo: string, pull_number: number) {
    await this.assertAuth();
    const res = await this.octokit.pulls.listCommits({
      owner,
      repo,
      pull_number,
    });

    return res.data;
  }

  async createPullRequest(
    owner: string,
    repo: string,
    base: string,
    head: string,
    title: string,
    body: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.pulls.create({
      owner,
      repo,
      base,
      head,
      title,
      body,
    });

    return res;
  }

  async labelPullRequest(
    owner: string,
    repo: string,
    number: number,
    label: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.issues.addLabels({
      owner,
      repo,
      issue_number: number,
      labels: [label],
    });

    return res;
  }

  async findPullRequest(
    owner: string,
    repo: string,
    base: string,
    head: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.pulls.list({
      owner,
      repo,
      base,
      head,
    });

    if (res.data.length > 0) {
      return res.data[0];
    }

    return undefined;
  }

  async getDefaultBranch(owner: string, repo: string) {
    await this.assertAuth();

    const res = await this.getRepo(owner, repo);
    return res.default_branch;
  }

  async listCollaborators(owner: string, repo: string) {
    await this.assertAuth();

    const query = `{
      repository(owner: "${owner}", name: "${repo}") {
        collaborators {
          nodes {
            login,
            name
          }
        }
      }
    }`;

    const res: RepoCollaboratorsResponse = await octocache.callGql(
      "repo.collaborators",
      query,
      (q: string) => this.executeGql(this.gql(q))
    );

    return res.repository.collaborators.nodes;
  }

  async searchAllUsers(q: string) {
    await this.assertAuth();

    const query = `{
      search(type: USER, first: 10, query: "${q}") {
        nodes {
          ... on User {
            login,
            name
          }
        }
      }
    }`;

    const res: SearchAllUsersResponse = await octocache.callGql(
      "search.users",
      query,
      (q: string) => this.executeGql(this.gql(q))
    );

    // Filter out any node without the 'login' property
    return res.search.nodes.filter((node) => node.login);
  }

  async listRecentBranches(
    owner: string,
    repo: string,
    coll: RecentBranchNode[] = [],
    cursor: string = ""
  ): Promise<RecentBranchNode[]> {
    await this.assertAuth();

    const query = `{
      repository(owner: "${owner}", name: "${repo}") {
        refs(first: 100, refPrefix: "refs/heads/", after: "${cursor}") {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            name
            target {
              ... on Commit {
                author {
                  user {
                    login
                  }
                }
                committedDate
              }
            }
          }
        }
      }
    }`;

    const res: RecentBranchesResponse = await this.executeGql(this.gql(query));

    const resNodes = res.repository.refs.nodes;
    const allNodes = [...coll, ...resNodes];

    // Recursively paginate
    const pageInfo = res.repository.refs.pageInfo;
    if (pageInfo.hasNextPage && allNodes.length < 500) {
      console.log("Paginating", allNodes.length);
      return this.listRecentBranches(owner, repo, allNodes, pageInfo.endCursor);
    }

    const nodes = allNodes.sort((a, b) => {
      const aTime = new Date(a.target.committedDate || 0).getTime();
      const bTime = new Date(b.target.committedDate || 0).getTime();

      return bTime - aTime;
    });

    return nodes;
  }

  async listPullRequestCommits(
    owner: string,
    repo: string,
    number: number
  ): Promise<PullRequestCommitNode[]> {
    await this.assertAuth();

    // TODO(polish): With more than 250 we'd need to paginate
    const query = `{
      repository(owner: "${owner}", name: "${repo}") {
        pullRequest(number: ${number}) {
          timelineItems(last: 250, itemTypes: [PULL_REQUEST_COMMIT]) {
            nodes {
              ... on PullRequestCommit {
                commit {
                  oid,
                  message,
                  committedDate,
                  pushedDate,
                  parents {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
    }`;

    const res: PullRequestCommitsResponse = await this.executeGql(
      this.gql(query)
    );
    return res.repository.pullRequest.timelineItems.nodes;
  }

  async compareCommits(
    owner: string,
    repo: string,
    base: string,
    head: string
  ) {
    await this.assertAuth();

    const res = await octocache.call(
      "repos.compareCommits",
      this.octokit.repos.compareCommits,
      {
        owner,
        repo,
        base,
        head,
      }
    );

    return res;
  }

  async getDiff(
    owner: string,
    repo: string,
    base: string,
    head: string
  ): Promise<ParsedDiff> {
    await this.assertAuth();

    const res = await octocache.call(
      "repos.compareCommits",
      this.octokit.repos.compareCommits,
      {
        owner,
        repo,
        base,
        head,
        mediaType: {
          format: "diff",
        },
      }
    );

    // The strange header changes the response type
    const data = (res as unknown) as string;
    return parseDiffString(data);
  }

  async getContentLine(
    owner: string,
    repo: string,
    path: string,
    ref: string,
    line: number
  ): Promise<string> {
    const [content] = await this.getContentLines(
      owner,
      repo,
      path,
      ref,
      line,
      line
    );
    return content;
  }

  async getBinaryFileContent(
    owner: string,
    repo: string,
    path: string,
    ref: string
  ) {
    await this.assertAuth();

    const res = await this.octokit.repos.getContent({
      owner,
      repo,
      path,
      ref,
    });

    if (res.data.content === "") {
      const rawUrl = res.data.download_url;
    }

    return {
      content: res.data.content,
      encoding: res.data.encoding,
      url: res.data.download_url,
    };
  }

  async getContentLines(
    owner: string,
    repo: string,
    path: string,
    ref: string,
    start: number,
    end?: number
  ): Promise<string[]> {
    await this.assertAuth();

    console.log(`getContentLines(${path}@${ref}, ${start}, ${end})`);

    const content = await this.getContent(owner, repo, path, ref);
    const lines = content.split("\n");

    // Drop the final blank line since it's not shown by convention
    if (lines[lines.length - 1] === "") {
      lines.pop();
    }

    if (!end) {
      return lines;
    }

    // File lines are 1-indexed in editors but 0-indexed in the array, hence start - 1
    // end is exclusive
    const slice = lines.slice(start - 1, end);
    return slice;
  }

  async getContent(
    owner: string,
    repo: string,
    path: string,
    ref: string
  ): Promise<string> {
    await this.assertAuth();

    const data = await octocache.call(
      "repos.getContent",
      this.octokit.repos.getContent,
      {
        owner,
        repo,
        path,
        ref,
      }
    );

    if (data.encoding === "base64") {
      if (typeof window !== "undefined" && window.atob) {
        // Browser, see:
        // https://stackoverflow.com/a/62537645/324977
        return decodeURIComponent(escape(window.atob(data.content)));
      } else {
        // Node
        return new Buffer(data.content, "base64").toString("utf-8");
      }
    }

    console.warn("Unknown encoding :" + data.encoding);
    return "";
  }

  async getRepo(owner: string, repo: string) {
    await this.assertAuth();

    const res = await this.octokit.repos.get({ owner, repo });
    return res.data;
  }

  async getPermissionForRepo(
    owner: string,
    repo: string,
    username: string
  ): Promise<string> {
    await this.assertAuth();

    // Note: this previously used this.octokit.repos.getCollaboratorPermissionLevel
    // to get the permission directly but that API now requires an 'admin' token

    const repoRes = await this.octokit.repos.get({ owner, repo });
    const { admin, maintain, pull, triage, push } = repoRes.data.permissions;
    if (admin) {
      return "admin";
    }

    if (maintain || triage || push) {
      return "write";
    }

    if (pull) {
      return "read";
    }

    return "none";
  }

  /**
   * Can only call this when authorized as an APP (JWT auth).
   */
  async getInstallationForRepo(
    owner: string,
    repo: string
  ): Promise<AppsGetRepoInstallationResponseData> {
    await this.assertAuth();

    const installationRes = await this.octokit.apps.getRepoInstallation({
      owner,
      repo,
    });

    return installationRes.data;
  }

  async listInstallationsForUser(): Promise<InstallationData[]> {
    await this.assertAuth();
    const installRes = await this.octokit.apps.listInstallationsForAuthenticatedUser();
    const installations = installRes.data.installations.filter(
      (i) => i.app_id === this.githubConfig.app_id
    );

    const res: InstallationData[] = [];

    for (const i of installations) {
      const repoRes = await this.octokit.apps.listInstallationReposForAuthenticatedUser(
        {
          installation_id: i.id,
        }
      );

      res.push({
        ...i,
        repositories: repoRes.data.repositories,
      });
    }

    return res;
  }

  async listReposVisibleForUser(
    type: "public" | "private" | "all"
  ): Promise<RepoData[]> {
    await this.assertAuth();

    const res = await this.octokit.repos.listForAuthenticatedUser({
      type,
    });
    return res.data;
  }

  async listReposForInstallation(installation_id: number) {
    await this.assertAuth();

    const repoRes = await this.octokit.apps.listInstallationReposForAuthenticatedUser(
      {
        installation_id: installation_id,
      }
    );

    return repoRes.data.repositories;
  }

  async getInstallationsForUser(): Promise<InstallationStatus> {
    await this.assertAuth();

    const installRes = await this.octokit.apps.listInstallationsForAuthenticatedUser();
    const installations = installRes.data.installations.filter(
      (i) => i.app_id === this.githubConfig.app_id
    );

    if (installations.length === 0) {
      return {
        installed: false,
      };
    }

    const repoNames: string[] = [];
    for (const i of installations) {
      console.log(
        `Installation type=${i.target_type}, login=${i.account.login}`
      );

      const repoRes = await this.octokit.apps.listInstallationReposForAuthenticatedUser(
        {
          installation_id: i.id,
        }
      );

      for (const r of repoRes.data.repositories) {
        if (!repoNames.includes(r.full_name)) {
          repoNames.push(r.full_name);
        }
      }
    }

    const res = {
      installed: true,
      repositories: repoNames.map((full_name) => {
        return { full_name };
      }),
    };

    return res;
  }

  async getAssignedPulls(login: string) {
    await this.assertAuth;
    return this.getPulls("review-requested", login);
  }

  async getOutgoingPulls(login: string) {
    await this.assertAuth();
    return this.getPulls("author", login);
  }

  async getOpenPulls(owner: string, repo: string) {
    await this.assertAuth();
    const pullsRes = await this.octokit.pulls.list({
      owner,
      repo,
      state: "open",
    });

    return pullsRes.data;
  }

  async listReviews(owner: string, repo: string, pull_number: number) {
    await this.assertAuth();

    // TODO(polish): What if there are more than 100?
    const res = await this.octokit.pulls.listReviews({
      owner,
      repo,
      pull_number,
      per_page: 100,
    });

    return res.data;
  }

  async dismissReview(
    owner: string,
    repo: string,
    pull_number: number,
    review_id: number,
    message: string
  ) {
    await this.assertAuth();

    await this.octokit.pulls.dismissReview({
      owner,
      repo,
      pull_number,
      review_id,
      message,
    });
  }

  async addPullRequestComment(
    owner: string,
    repo: string,
    pull_number: number,
    body: string
  ) {
    await this.assertAuth();
    await this.octokit.issues.createComment({
      owner,
      repo,
      issue_number: pull_number,
      body,
    });
  }

  async createReview(
    owner: string,
    repo: string,
    pull_number: number,
    event: "APPROVE" | "REQUEST_CHANGES" | "COMMENT",
    body: string
  ) {
    await this.assertAuth();
    await this.octokit.pulls.createReview({
      owner,
      repo,
      pull_number,
      event,
      body,
    });
  }

  async getIssueOrPullRequest(owner: string, repo: string, number: number) {
    await this.assertAuth();

    try {
      const res = await this.octokit.issues.get({
        owner,
        repo,
        issue_number: number,
      });
      return {
        title: res.data.title,
        number,
        url: res.data.html_url,
        pull_request: !!res.data.pull_request,
      };
    } catch (e) {
      return undefined;
    }
  }

  async executeGql(req: ReturnType<typeof graphql>) {
    try {
      return await req;
    } catch (e) {
      const err = e as any;
      if (err.data) {
        console.warn(`Partial GraphQL response: ${err.message}`);
        console.warn(err.request);
        return err.data;
      }

      throw e;
    }
  }

  // TODO(polish): These params should be GQL variables not format strings...
  async getPulls(filter: "review-requested" | "author", login: string) {
    await this.assertAuth();

    const res = await this.executeGql(
      this.gql({
        query: `query pulls {
        search(query: "is:pull-request is:open ${filter}:${login}", type: ISSUE, last: 25) {
          edges {
            node {
              ... on PullRequest {
                title
                number
                repository {
                  owner {
                    login
                  }
                  name
                },
                closed
                merged
                updatedAt
              }
            }
          }
        }
      }`,
      })
    );

    const nodes = (res as any).search.edges
      .filter((e: any) => e != null)
      .map((e: any) => e.node);

    return nodes as PullRequestNode[];
  }
}
