export interface MaybePreventTriggers {
  // An optional date field. If this field is updated to a later
  // date during a write we should prevent triggers.
  _preventTriggers?: number;
}

export interface Installation {
  installation_id: number;
  repo_id: number;
}

export interface Org {
  plan: "free" | "open-source" | "team";
  billing_admins?: string[];
  free_trial?: boolean;
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  auto_review?: boolean;
}

export interface Repo {
  owner: string;
  name: string;
  private: boolean;

  // When true, always create a CodeApprove review when a PR is opened.
  // Default: false
  auto_review?: boolean;

  // When true, default the "resolve" toggle to on when replying to a thread
  // Default: true
  default_resolve_threads?: boolean;
}

export interface User {
  login: string;
  email?: string;
  access_token: string;
  access_token_expires: number;
  refresh_token: string;
  refresh_token_expires: number;

  /**
   * Special admin-only boolean.
   */
  admin?: boolean;
}

export interface UserFavoriteRepo {
  owner: string;
  name: string;
}

export interface UserRepos {
  repo_names: string[];
  // TODO(stop): Can/should make this required
  favorite_repos?: UserFavoriteRepo[];
}

export interface UserInvite {
  type: "user";
  email: string;
  accepted?: boolean;
  invited_by?: string;
}

export interface DomainInvite {
  type: "domain";
  domain: string;
}

export type Invite = UserInvite | DomainInvite;

export interface SignUpRequest {
  email: string;
  company_name: string;
  github_url: string;
}

export type ReviewIdentifier = Pick<
  ReviewMetadata,
  "owner" | "repo" | "number"
>;

export interface ReviewPointer {
  label: string;
  ref: string;
  sha: string;
  user: {
    login: string;
  };
}

export interface ReviewMetadata extends ReviewIdentifier {
  owner: string;
  repo: string;
  number: number;
  author: string;
  title: string;
  body: string | null;
  base: ReviewPointer;
  head: ReviewPointer;
  updated_at: number;
}

// Type representing incoming GitHub data (from API or WebHook) that
// we can use to construct a ReviewMetadata:
//  - owner/repo/author fields are added from elsewhere
//  - updated_at is a string on GitHub, we want a number for sorting
export type ReviewMetadataSource = Omit<
  ReviewMetadata,
  "owner" | "repo" | "author" | "updated_at"
> & {
  user: {
    login: string;
  };
  updated_at: string;
  created_at: string;
};

export enum ReviewStatus {
  // Approved and all comments resolved
  APPROVED = "approved",

  // Closed and all commits merged into the target branch
  CLOSED_MERGED = "closed_merged",

  // Closed before merging (abandoned)
  CLOSED_UNMERGED = "closed_unmerged",

  // Approved by someone, but has unresolved comments
  NEEDS_RESOLUTION = "needs_resolution",

  // Has reviewers, but not yet approved by any
  NEEDS_APPROVAL = "needs_approval",

  // Nobody has reviewed this yet
  NEEDS_REVIEW = "needs_review",
}

export interface ReviewState {
  // Overall status
  status: ReviewStatus;

  // Open/closed state
  closed: boolean;

  // Anyone added as a reviewer
  reviewers: string[];

  // Those who have actually approved it
  approvers: string[];

  // Who needs to take a look now
  assignees: string[];

  // Cc'ed users
  ccs: string[];

  // Number of unresolved threads
  unresolved: number;

  // Time of the last comment
  last_comment: number;
}

export interface Review extends MaybePreventTriggers {
  metadata: ReviewMetadata;
  state: ReviewState;
}

/**
 * When a user sends a review a history item is added.
 */
export interface ReviewHistory extends MaybePreventTriggers {
  // Reviewer username
  username: string;

  // Did the reviewer approve it?
  approval: boolean | null;
  previousApproval: boolean | null;

  // List of actions they took
  actions: ReviewAction[];

  // Timestamp
  timestamp: number;

  /**
   * Metadata for easier querying. Not present on histories
   * before January 2023!
   *
   * TODO(polish): Backfill this on all histories
   */
  metadata: {
    owner: string;
    repo: string;
  };
}

export type ReviewAction =
  | CreateReviewAction
  | AddThreadAction
  | ResolveThreadAction
  | AddCommentAction
  | AddReviewerAction
  | RemoveReviewerAction
  | AddAssigneeAction
  | RemoveAssigneeAction
  | AddCcAction
  | RemoveCcAction;

export interface AddThreadAction {
  type: "add_thread";
  threadId: string;
}

export interface ResolveThreadAction {
  type: "resolve_thread";
  threadId: string;
  resolved: boolean;
}

export interface AddCommentAction {
  type: "add_comment";
  commentId: string;
  threadId: string;
}

export interface AddReviewerAction {
  type: "add_reviewer";
  username: string;
}

export interface RemoveReviewerAction {
  type: "remove_reviewer";
  username: string;
}

export interface AddAssigneeAction {
  type: "add_assignee";
  username: string;
}

export interface RemoveAssigneeAction {
  type: "remove_assignee";
  username: string;
}

export interface AddCcAction {
  type: "add_cc";
  username: string;
}

export interface RemoveCcAction {
  type: "remove_cc";
  username: string;
}

export interface CreateReviewAction {
  type: "create_review";
}

/**
 * Per-user data on a review.
 */
export interface ReviewUserData extends MaybePreventTriggers {
  // Map of file names reviewed to the SHA at which they
  // were last reviewed
  filesReviewed: Record<string, string>;
}

export type Side = "left" | "right";

export type DiffMode = "unified" | "split";

export type SortOrder = "diff-desc" | "diff-asc" | "name";

export type DiffServer = "github" | "codeapprove";

export type ThemeName = "dark" | "light";

export const THREAD_LINE_DISCUSSION = 0;
export const THREAD_LINE_OUTDATED = -1;
export const THREAD_LINE_WHOLE_FILE = -3;

export interface ThreadArgs {
  file: string;
  sha: string;
  side: Side;
  line: number;
  lineContent: string;
}

// In the future might want to add things like:
//  - timestamp
//  - reviewHistoryId
//  - commentId
export interface ThreadResolutionChange {
  username: string;
}

export interface Thread extends MaybePreventTriggers {
  id: string;
  username: string;
  draft: boolean;
  resolved: boolean;

  currentArgs: ThreadArgs;
  originalArgs: ThreadArgs;

  lastValidArgs?: ThreadArgs;

  lastUnresolvedBy: ThreadResolutionChange;
  lastResolvedBy: ThreadResolutionChange | null;
}

export interface CommentUser {
  username: string;
  photoURL: string;
}

export interface Comment extends CommentUser, MaybePreventTriggers {
  id: string;
  threadId: string;
  setThreadResolution: boolean | null;
  draft: boolean;
  timestamp: number;
  text: string;

  // When the comment is left as part of the "finish review" flow
  reviewSummary?: boolean;
}

export interface RepoMove {
  from: {
    owner: string;
    repo: string;
  };
  to: {
    owner: string;
    repo: string;
  };
  action: string;
  timestamp: number;
  eventId: string;
}

export interface GithubConfig {
  app_name: string;
  app_id: number;
  client_id: string;
}

export interface ClientGitHubConfig extends GithubConfig {
  app_url: string;
  redirect: string;
}

export interface ServerGithubConfig extends GithubConfig {
  client_secret: string;
  webhook_secret: string;
  private_key_encoded: string;
}

export interface RefreshTokenResponse {
  access_token: string;
  access_token_expires: number;
}

export interface RepoBranchPair {
  owner: string;
  repo: string;
  base: string;
  head: string;
}

export interface UserSearchItem {
  id: string;
  login: string;
  name: string | null;
  collaborator: boolean;
}

export interface BillingInfo {
  plan: string;
  freeTrial: boolean;
  hasPrivateRepos: boolean;
  priceInCents: number;
  activeUsers: string[];
}

export type LineLocator = {
  commit: string;
  file: string;
  line: number;
};
