import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

export interface Message {
  type: "blocking_error" | "error" | "alert";
  text: string;
  action?: string;
}

@Module({
  name: "ui"
})
export default class UIModule extends VuexModule {
  public loading = false;
  public showHotkeyModal = false;

  public messages: Message[] = [];

  @Action({ rawError: true })
  async addDisappearingError(text: string) {
    const err = {
      type: "error",
      text
    };

    this.context.commit("addMessage", err);
    setTimeout(() => {
      this.context.commit("dismissMessage", err);
    }, 15000);
  }

  @Mutation
  clearMessages() {
    this.messages = [];
  }

  @Mutation
  clearNonErrors() {
    this.messages = this.messages.filter(m => m.type !== "error");
  }

  @Mutation
  addMessage(message: Message) {
    if (this.messages.some(m => m.text === message.text)) {
      return;
    }

    this.messages.unshift(message);
  }

  @Mutation
  dismissMessage(msg: Message) {
    const ind = this.messages.indexOf(msg);
    if (ind >= 0) {
      this.messages.splice(ind, 1);
    }
  }

  @Mutation
  beginLoading() {
    this.loading = true;
  }

  @Mutation
  endLoading() {
    this.loading = false;
  }

  @Mutation
  setShowHotkeyModal(value: boolean) {
    this.showHotkeyModal = value;
  }
}
