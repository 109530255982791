import * as parseDiff from "parse-diff";

import { freezeArray } from "../../../shared/freeze";
import {
  DiffMode,
  DiffServer,
  Side,
  Thread,
  ThreadArgs,
  THREAD_LINE_OUTDATED,
  THREAD_LINE_WHOLE_FILE
} from "../../../shared/types";
import { SidePair } from "../model/review";

import * as fad from "fast-array-diff";
import { Token, TokenStream, Environment, Grammar } from "prismjs";
import { hashCode } from "./hash";
import * as api from "./api";
import { ParsedDiff, parseDiffString } from "../../../shared/diffUtils";
import { Github } from "../../../shared/github";

const Prism = require("prismjs");

Prism.hooks.add("after-tokenize", (env: Environment) => {
  env.tokens = processTokens(env.tokens);
});

function processTokens(tokens: TokenStream): TokenList {
  if (!Array.isArray(tokens)) {
    return [tokens];
  }

  const newTokens = [];

  for (const t of tokens) {
    if (typeof t !== "string") {
      newTokens.push(t);
      continue;
    }

    if (t.trim() === "") {
      newTokens.push(new Prism.Token("str", t));
      continue;
    }

    const split = separateWhitespace(t).map(s => new Prism.Token("str", s));
    newTokens.push(...split);
  }

  return newTokens;
}

function separateWhitespace(t: string): string[] {
  const newTokens = [];

  // Move forward from the front of the string to find the first non-space
  let firstNonSpace = 0;
  while (firstNonSpace < t.length) {
    if (t.charAt(firstNonSpace) !== " ") {
      break;
    }
    firstNonSpace++;
  }

  // Move backward from the end of the string to find the last non-space
  let lastNonSpace = t.length - 1;
  while (lastNonSpace > 0) {
    if (t.charAt(lastNonSpace) !== " ") {
      break;
    }
    lastNonSpace--;
  }

  // Split the token into up to three chunks
  const leadingSpaces = t.substring(0, firstNonSpace);
  const middle = t.substring(firstNonSpace, lastNonSpace + 1);
  const trailingSpaces = t.substring(lastNonSpace + 1, t.length);

  if (leadingSpaces !== "") {
    newTokens.push(leadingSpaces);
  }

  newTokens.push(middle);

  if (trailingSpaces !== "") {
    newTokens.push(trailingSpaces);
  }

  return newTokens;
}

export interface FileMetadata {
  from: string;
  to: string;
  additions: number;
  deletions: number;
}

export interface RenderedChange {
  type: "add" | "del" | "normal";
  empty: boolean;
  number: number;
  marker: string;
  content: string;
}

export interface RenderedChangePair extends SidePair<RenderedChange> {
  commentsEnabled: boolean;
}

export interface ChangePair extends SidePair<parseDiff.Change | undefined> {}

export interface ChunkHeader {
  start: SidePair<number>;
  length: SidePair<number>;
}

export interface ChunkData {
  chunk: parseDiff.Chunk;
  pairs: RenderedChangePair[];
}

export interface PullRequestChange {
  key: string;
  file: parseDiff.File;
  metadata: FileMetadata;
  data: ChunkData[];
}

export type RenderedPullRequest = Record<string, PullRequestChange>;

export const START_CHUNK: parseDiff.Chunk = {
  content: "",
  changes: [],
  oldStart: 0,
  oldLines: 1,
  newStart: 0,
  newLines: 1
};

export const END_CHUNK: parseDiff.Chunk = {
  content: "",
  changes: [],
  oldStart: Number.MAX_SAFE_INTEGER - 1,
  oldLines: 1,
  newStart: Number.MAX_SAFE_INTEGER - 1,
  newLines: 1
};

export const EMPTY_RENDERED: RenderedChange = {
  type: "normal",
  empty: true,
  number: -999, // Some negative numbers have meaning
  marker: " ",
  content: ""
};

export function fileMetadataTitle(meta: FileMetadata) {
  const added = meta.from === "/dev/null";
  const deleted = meta.to === "/dev/null";

  if (meta.from === meta.to) {
    return meta.from;
  } else if (added) {
    return `${meta.to}`;
  } else if (deleted) {
    return `${meta.from}`;
  } else {
    return `${meta.from} -> ${meta.to}`;
  }
}

export function getFileMetadata(file: parseDiff.File): FileMetadata {
  return {
    from: file.from || "unknown",
    to: file.to || "unknown",
    additions: file.additions,
    deletions: file.deletions
  };
}

export function renderLoadedLineChange(
  line: number,
  content: string
): RenderedChange {
  // Note: we add a space before "content" because in a GitHub diff all normal
  // lines come preceded by a space (to occupy where a '+' or '-' would be)
  return {
    type: "normal",
    empty: false,
    number: line,
    marker: "",
    content: " " + content
  };
}

export function renderPairs(pairs: ChangePair[]): RenderedChangePair[] {
  const rps = pairs.map(p => {
    return {
      left: renderChange(p.left, "left"),
      right: renderChange(p.right, "right"),
      commentsEnabled: true
    };
  });

  // Special handling for " No newline at end of file" which GitHub sends as part of the diff
  // TODO(polish): show some visual indicator of newline removal
  //
  // TODO(polish): this does not handle if the "no newline at end of file" is on the left! And it
  //               may not be the last change pair if lines were added to the end of a file which
  //               previously did not end in a newline.
  const noNewLine = " No newline at end of file";
  if (rps.length > 0) {
    const lastPair = rps[rps.length - 1];
    if (
      (lastPair.left.empty || lastPair.left.content === noNewLine) &&
      lastPair.right.content === noNewLine
    ) {
      rps.pop();
    }
  }

  return freezeArray(rps);
}

/**
 * Choose the relevant args for a thread based on the visible SHA range.
 */
export function chooseThreadArgs(thread: Thread, base: string, head: string) {
  const isOutdated = thread.currentArgs.line === THREAD_LINE_OUTDATED;

  const originalContextVisible =
    thread.originalArgs.sha === base || thread.originalArgs.sha === head;

  const newArgs =
    isOutdated && thread.lastValidArgs
      ? thread.lastValidArgs
      : thread.currentArgs;

  // We prefer original args, followed by the new args (which are either
  // the last valid args or the current one)
  const args = originalContextVisible ? thread.originalArgs : newArgs;

  return args;
}

/**
 * Create a map of line keys to thread ids to be used in UI lookups.
 */
export function getThreadLocationMap(
  changes: RenderedPullRequest,
  threads: Thread[],
  visibleShas: string[]
) {
  const map: Record<string, string> = {};

  // Filter out threads for the whole PR (discussions)
  const lineThreads = threads.filter(t => {
    if (t.originalArgs.sha === "") {
      return false;
    }

    return true;
  });

  for (const thread of lineThreads) {
    const base = visibleShas[0];
    const head = visibleShas[visibleShas.length - 1];

    // Choose the relevant args given the visible shas
    const args = chooseThreadArgs(thread, base, head);

    // Determine if the base or the head is the exact sha we're looking for
    const exactShaVisible = args.sha === base || args.sha === head;

    // Determine if the sha we're looking for is visible at all
    const shaInVisibleRange = visibleShas.indexOf(args.sha) >= 0;

    // We only show comments on the right if they match the head sha. Everything
    // in between is shown on the left or not at all.
    const side: Side = args.sha === head ? "right" : "left";

    // We try to show a thread if we can attach it to its exact context or if
    // if it is unresolved and the comment is somewhere in the displayed sha
    // range.
    const shouldTryToShow =
      exactShaVisible || (shaInVisibleRange && !thread.resolved);

    // Map from location -> thread so that the UI can easily look up what threads
    // to show on a given line.
    const locationId = getThreadLocationId(changes, args, side);

    if (locationId && !shouldTryToShow) {
      console.warn("Got location for a thread, but shouldTryToShow=false", {
        threadId: thread.id,
        args,
        locationId
      });
    }

    if (locationId && shouldTryToShow) {
      map[locationId] = thread.id;
    }
  }

  return map;
}

export function changeMatchesThreadArgs(
  change: PullRequestChange,
  args: ThreadArgs,
  side: Side
) {
  if (side === "right") {
    return change.file.to === args.file;
  } else {
    return change.file.from === args.file;
  }
}

/**
 * Find the specific line where a Thread should be displayed.
 */
export function getThreadLocation(
  changes: RenderedPullRequest,
  args: ThreadArgs,
  side: Side
) {
  const matchingChange = Object.values(changes).find(c =>
    changeMatchesThreadArgs(c, args, side)
  );

  if (!matchingChange) {
    return {};
  }

  const chunks = matchingChange.data;
  const matchingChunkIndex = chunks.findIndex(c => {
    const start = side === "right" ? c.chunk.newStart : c.chunk.oldStart;
    const numLines = side === "right" ? c.chunk.newLines : c.chunk.oldLines;
    const end = start + numLines - 1;

    return args.line >= start && args.line <= end;
  });

  if (matchingChunkIndex < 0) {
    return {
      matchingChange
    };
  }

  const matchingChunk = chunks[matchingChunkIndex];

  const pairs = matchingChunk.pairs;
  const matchingPairIndex = pairs.findIndex(p => {
    const lineContent = p[side].content;
    const line = p[side].number;

    const lineContentExact = args.lineContent === lineContent;

    // TODO(polish): This absolutely shoudl not be necessary but I found
    // at least one case where the content was off by a single leading space
    // for no apparent reason.
    const lineContentFuzzy =
      args.lineContent.trimStart() === lineContent.trimStart() &&
      lineContent.trimStart().length > 5;

    return args.line === line && (lineContentExact || lineContentFuzzy);
  });

  if (matchingPairIndex < 0) {
    return {
      matchingChange,
      matchingChunk,
      matchingChunkIndex
    };
  }

  const matchingPair = pairs[matchingPairIndex];

  return {
    matchingChange,
    matchingChunkIndex,
    matchingChunk,
    matchingPairIndex,
    matchingPair
  };
}

/**
 * Find the specific line where a Thread should be displayed.
 */
export function getThreadLocationId(
  changes: RenderedPullRequest,
  args: ThreadArgs,
  side: Side
) {
  const {
    matchingChange,
    matchingChunk,
    matchingChunkIndex,
    matchingPair
  } = getThreadLocation(changes, args, side);

  // We consider whole-file comments to be in the "-1" chunk.
  if (matchingChange && args.line === THREAD_LINE_WHOLE_FILE) {
    const locationIdPrefix = getLocationIdPrefix(matchingChange.key, -1);
    return getLocationId(locationIdPrefix, args.line, side);
  }

  if (!matchingChange || !matchingChunk || !matchingPair) {
    return;
  }

  if (typeof matchingChunkIndex !== "number" || matchingChunkIndex < 0) {
    return;
  }

  const locationIdPrefix = getLocationIdPrefix(
    matchingChange.key,
    matchingChunkIndex
  );

  const lineNumber = matchingPair[side].number;
  return getLocationId(locationIdPrefix, lineNumber, side);
}

export function getLocationIdPrefix(changeKey: string, chunkIndex: number) {
  // TODO: Is the chunk index a stable identifier?
  return `change-${changeKey}-chunk-${chunkIndex}`;
}

export function getLocationId(
  locationIdPrefix: string,
  lineNumber: number,
  side: Side
) {
  return `${locationIdPrefix}-line-${lineNumber}-side-${side}`;
}

function zipArrays<T>(a: T[], b: T[]): T[] {
  const maxLen = Math.max(a.length, b.length);
  const res = new Array<T>();

  for (let i = 0; i < maxLen; i++) {
    if (i < a.length) {
      res.push(a[i]);
    }

    if (i < b.length) {
      res.push(b[i]);
    }
  }

  return res;
}

function sortChunkChanges(chunk: parseDiff.Chunk, mode: DiffMode) {
  // In unified mode we just flatten the runs out
  if (mode === "unified") {
    return [...chunk.changes];
  }

  // First group the changes into "runs" of the same type
  const runs: parseDiff.Change[][] = [];
  let currRun: parseDiff.Change[] = [];
  for (const change of chunk.changes) {
    if (currRun.length === 0) {
      currRun.push(change);
    } else {
      if (change.type === currRun[0].type) {
        currRun.push(change);
      } else {
        runs.push(currRun);
        currRun = [change];
      }
    }
  }
  runs.push(currRun);

  // Now flatten things out but if there's a "del" run next to an "add"
  // run we zip them together.
  const res = new Array<parseDiff.Change>();
  let i = 0;
  while (i < runs.length) {
    if (i < runs.length - 1) {
      const a = runs[i];
      const b = runs[i + 1];

      if (a[0].type === "del" && b[0].type === "add") {
        res.push(...zipArrays(a, b));
        i += 2;
      } else {
        res.push(...a);
        i += 1;
      }
    } else {
      res.push(...runs[i]);
      i += 1;
    }
  }

  return res;
}

/**
 * Zip the list of changes from a chunk into an array of pairs ready to be diffed side-by-side.
 */
export function zipChangePairs(
  chunk: parseDiff.Chunk,
  mode: DiffMode
): ChangePair[] {
  const res: ChangePair[] = [];
  const changes = sortChunkChanges(chunk, mode);

  let i = 0;
  while (i < changes.length) {
    const change = changes[i];

    // In "split" mode, del followed by add means the lines "match"
    if (mode === "split" && i < changes.length - 1) {
      const next = changes[i + 1];
      if (change.type === "del" && next.type === "add") {
        res.push({
          left: change,
          right: next
        });

        // Extra increment
        i += 2;
        continue;
      }
    }

    if (change.type === "del") {
      res.push({ left: change, right: undefined });
    } else if (change.type === "add") {
      res.push({ left: undefined, right: change });
    } else {
      res.push({ left: change, right: change });
    }

    i++;
  }

  return res;
}

export function renderChange(
  change: parseDiff.Change | undefined,
  side: Side
): RenderedChange {
  // TODO(polish): Drop this block and remove optional
  if (!change) {
    return EMPTY_RENDERED;
  }

  return {
    type: change.type,
    empty: false,
    number: changeLineNumber(change, side),
    content: changeContent(change),
    marker: changeLineMarker(change)
  };
}

export function changeLineMarker(change: parseDiff.Change): string {
  switch (change.type) {
    case "del":
      return "-";
    case "add":
      return "+";
    default:
      return " ";
  }
}

export function changeContent(change: parseDiff.Change): string {
  // Remove the '+', '-', or ' ' from the start of the line
  return change.content.substring(1);
}

export function changeLineNumber(change: parseDiff.Change, side: Side): number {
  switch (change.type) {
    case "add":
      return change.ln;
    case "del":
      return change.ln;
    case "normal":
      if (side === "left") {
        return change.ln1;
      } else {
        return change.ln2;
      }
  }
}

function stringifyTokenStream(stream: TokenStream): string {
  // TokenStream = string | Token | Array<string | Token>;
  if (typeof stream === "string") {
    return stream;
  }

  if (Array.isArray(stream)) {
    return stream.map(x => stringifyTokenStream(x)).join("");
  }

  return stringifyTokenStream(stream.content);
}

function stringifyTokenList(tokens: TokenList) {
  return tokens.map(t => {
    if (typeof t === "string") {
      return t;
    } else {
      return stringifyTokenStream(t.content);
    }
  });
}

export type TokenList = Array<string | Token>;

export interface SubLineDiff {
  tokens: {
    left: TokenList;
    right: TokenList;
  };
  patch: {
    added: number[];
    removed: number[];
    addedChars: number;
    removedChars: number;
  };
}

// Opening html tag at the start of the line, optionally
// preceded by whitespace
const RE_STARTING_OPEN = /^\s*?(<([^/]{1}[^\s]+).*?>)/;

// Closing html tag at the start of the line, optionally
// preceded by whitespace
const RE_STARTING_CLOSE = /^\s*?(<\/.+?>)/;

// Any html tag (opening or closing). Group 1 is the tag name.
const RE_TAG = /<[/]?([^\s>]+).*?>/g;

export function highlightContent(content: string, lang: string) {
  return Prism.highlight(content, Prism.languages[lang], lang) as string;
}

function findUnclosedTags(l: string) {
  const tagMatches = [...l.matchAll(RE_TAG)];
  const tagChain = [];

  for (const t of tagMatches) {
    const isOpen = !t[0].startsWith("</");

    const tagName = t[1];
    const tagContent = t[0];
    if (isOpen) {
      tagChain.push({
        tagName,
        tagContent
      });
    } else if (tagChain.length > 0) {
      const lastTag = tagChain[tagChain.length - 1];
      if (lastTag.tagName === tagName) {
        tagChain.pop();
      }
    }
  }

  return tagChain;
}

/**
 * Look at a Prism-highlighted line of HTML and pull out the most important
 * information so that it can be re-arranged.
 */
function evaluateLine(l: string) {
  const startingOpenMatch = l.match(RE_STARTING_OPEN);
  const startingCloseMatch = l.match(RE_STARTING_CLOSE);

  const startingOpen = startingOpenMatch ? startingOpenMatch[0] : null;
  const startingClose = startingCloseMatch ? startingCloseMatch[1] : null;

  const unclosedTags = findUnclosedTags(l);
  const lastUnclosed =
    unclosedTags.length > 0 ? unclosedTags[unclosedTags.length - 1] : null;

  return {
    content: l,
    fixedContent: l,
    startingOpen,
    startingClose,
    lastUnclosed
  };
}

/**
 * This parses Prism's highlighted HTML content of a whole chunk and turns it
 * back into a series of lines which we can render as a diff.
 *
 * A lot of the logic is around rebalancing opening and closing tags since Prism
 * likes to put closing tags on the second line to accuont for newlines.
 */
export function getLinesFromHighlightedContent(highlighted: string) {
  const highlightedLines = highlighted
    .split("\r\n")
    .join("\n")
    .split("\n");

  // Parse the HTML of each line
  let lineInfos = highlightedLines.map(l => evaluateLine(l));

  // If a line starts with a close tag, pull that up to the previous line
  for (let i = 1; i < lineInfos.length; i++) {
    const prev = lineInfos[i - 1];
    const cur = lineInfos[i];
    if (cur.startingClose) {
      // Add the tag to the previous line
      prev.fixedContent += cur.startingClose;

      // Remove the tag from the current line
      cur.fixedContent = cur.fixedContent.replace(cur.startingClose, "");
    }
  }

  // Re-evaluate all lines again, since we rebalanced tags
  lineInfos = lineInfos.map(li => evaluateLine(li.fixedContent));

  // If a line does not start with an opening tag, assume it should have
  // the same opening tag as the last unclosed one
  let lastUnclosed = lineInfos[0].lastUnclosed;
  for (let i = 1; i < lineInfos.length; i++) {
    const cur = lineInfos[i];
    if (!cur.startingOpen && lastUnclosed) {
      cur.fixedContent = lastUnclosed.tagContent + cur.fixedContent;
    }

    if (cur.lastUnclosed) {
      lastUnclosed = cur.lastUnclosed;
    }
  }

  // Close any line that's not closed.
  for (const li of lineInfos) {
    const unclosedTags = findUnclosedTags(li.fixedContent);

    if (unclosedTags.length > 0) {
      const lastUnclosed = unclosedTags[unclosedTags.length - 1];
      li.fixedContent = li.fixedContent + `</${lastUnclosed.tagName}>`;
    }
  }

  return lineInfos.map(li => li.fixedContent);
}

// TODO(polish): This is now unused
export function tokenize(content: string, grammar: Grammar) {
  return processTokens(Prism.tokenize(content, grammar));
}

// TODO(polish): This is now unused
export function subLineDiff(
  langs: SidePair<string>,
  content: SidePair<string>
): SubLineDiff {
  const leftTokens: TokenList = tokenize(
    content.left,
    Prism.languages[langs.left] || Prism.languages.markup
  );
  const rightTokens: TokenList = tokenize(
    content.right,
    Prism.languages[langs.right] || Prism.languages.markup
  );

  const res: SubLineDiff = {
    tokens: {
      left: leftTokens,
      right: rightTokens
    },
    patch: {
      added: [],
      removed: [],
      addedChars: 0,
      removedChars: 0
    }
  };

  const sameLang = langs.right === langs.left;
  const bothContent = content.right.length > 0 && content.left.length > 0;
  if (sameLang && bothContent) {
    const left = stringifyTokenList(leftTokens);
    const right = stringifyTokenList(rightTokens);

    const patch = fad.getPatch(left, right);

    const added = [];
    const removed = [];

    let addedChars = 0;
    let removedChars = 0;

    // Take the patch object and convert it to a simple list of indexes added and removed
    for (const p of patch) {
      if (p.type === "add") {
        for (let i = 0; i < p.items.length; i++) {
          added.push(p.newPos + i);
          addedChars += p.items[i].length;
        }
      }

      if (p.type === "remove") {
        for (let i = 0; i < p.items.length; i++) {
          removed.push(p.oldPos + i);
          removedChars += p.items[i].length;
        }
      }
    }

    res.patch = {
      added,
      removed,
      addedChars,
      removedChars
    };
  }

  return res;
}

export function renderDiffToChange(
  file: parseDiff.File,
  mode: DiffMode
): PullRequestChange {
  const metadata: FileMetadata = getFileMetadata(file);
  const data: ChunkData[] = file.chunks.map(chunk => {
    return {
      chunk,
      pairs: freezeArray(renderPairs(zipChangePairs(chunk, mode)))
    };
  });

  const key = hashCode(`${metadata.from}-${metadata.to}`);

  return {
    key,
    file,
    metadata,
    data
  };
}

export function renderPullRequest(
  diffs: parseDiff.File[],
  mode: DiffMode
): RenderedPullRequest {
  const changes: PullRequestChange[] = diffs.map(file => {
    return renderDiffToChange(file, mode);
  });

  const map: RenderedPullRequest = {};
  for (const c of changes) {
    map[c.key] = c;
  }

  return map;
}

async function getDiffCodeApprove(
  owner: string,
  repo: string,
  base: string,
  head: string,
  branch: string,
  depth: number,
  options: {
    github: Github;
  }
) {
  const token = await options.github.assertAuthToken();
  const res = await api.get(
    `/internal/diff/${owner}/${repo}?base=${base}&head=${head}&branch=${branch}&depth=${depth}`,
    {
      headers: {
        "x-codeapprove-github-token": token
      }
    }
  );

  return parseDiffString(res.diff);
}

export async function getDiff(
  owner: string,
  repo: string,
  base: string,
  head: string,
  branch: string,
  depth: number,
  options: {
    github: Github;
    diffServer: DiffServer;
  }
): Promise<ParsedDiff> {
  return options.diffServer === "codeapprove"
    ? await getDiffCodeApprove(owner, repo, base, head, branch, depth, options)
    : await options.github.getDiff(owner, repo, base, head);
}
