




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ProgressBar from "@/components/elements/ProgressBar.vue";

interface SearchItem {
  id: string;
}

@Component({
  components: {
    ProgressBar
  }
})
export default class SearchModal extends Vue {
  @Prop() placeholder!: string;
  @Prop() items!: SearchItem[];
  @Prop({ default: false }) loading!: boolean;

  public query: string = "";
  public activeIndex = 0;

  async mounted() {
    (this.$refs.searchField as any).focus();
  }

  @Watch("items")
  async onItems() {
    this.activeIndex = 0;
  }

  @Watch("query")
  async onQuery() {
    this.$emit("input", this.query);
  }

  get keymap() {
    return {
      down: this.onArrowDown,
      up: this.onArrowUp,
      enter: this.onEnter
    };
  }

  private onArrowUp() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  private onArrowDown() {
    if (this.activeIndex < this.items.length - 1) {
      this.activeIndex++;
    }
  }

  private onEnter() {
    this.selectItem(this.activeIndex);
  }

  public selectItem(index: number) {
    if (index >= this.items.length) {
      return;
    }

    const item = this.items[index];
    this.$emit("selected", { item });
  }
}
