


































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { HotkeyModalAPI } from "../api";
import UIModule from "../../store/modules/ui";
import {
  KeyDescMap,
  KeyMap,
  HOTKEY_MODAL_KEY_MAP,
  getCtrlKeyCode,
  getCtrlKeyName
} from "../../plugins/hotkeys";
import * as cookies from "../../plugins/cookies";

@Component
export default class HotkeyModal extends Vue implements HotkeyModalAPI {
  @Prop() map!: KeyDescMap;

  uiModule = getModule(UIModule, this.$store);
  private _show = false;

  get keymap(): KeyMap {
    return HOTKEY_MODAL_KEY_MAP(this);
  }

  public splitKey(key: string) {
    return key
      .split(",")
      .map(k => k.replace(getCtrlKeyCode(), getCtrlKeyName()));
  }

  public onShow() {
    this.$cookies.set(cookies.HOTKEY_MODAL_SHOWN, "true");
    this.uiModule.setShowHotkeyModal(true);
  }

  public onHide() {
    this.uiModule.setShowHotkeyModal(false);
  }

  get show() {
    return this.uiModule.showHotkeyModal || this._show;
  }
}
