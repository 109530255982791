











import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TwoLineSelectItem extends Vue {
  @Prop() message!: string;
  @Prop() submessage!: string;
  @Prop() active!: boolean;
}
